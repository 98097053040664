import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { selectUserRole, UserRole } from "redux/userRedux";
import { selectCanControl } from "redux/settingsRedux";
import { ReactComponent as CloseFullscreenIcon } from "assets/icons/close_fullscreen.svg";
import { ReactComponent as ExpandIcon } from "assets/icons/expand_fullscreen.svg";
import {
  selectIsFullScreenVideo,
  selectMeetingID,
  setIsFullScreenVideo,
} from "redux/spaceNavigationRedux";
import { logUsageEvent, useTrackEvent } from "utils/metricsUtils";
import { VideoButton } from "pages/Space/components/Videos/VideosButtons/VideoButton";
import styles from "./ExpandButton.module.css";
import clsx from "clsx";

type ExpandButtonProps = {
  setRemoteFullScreenVideoConferencing: (isFullScreen: boolean) => void;
  className: string;
  style?: React.CSSProperties;
};

export const ExpandButton = ({
  setRemoteFullScreenVideoConferencing,
  className,
  style,
}: ExpandButtonProps) => {
  const userRole = useSelector(selectUserRole);
  const isFullScreen = useSelector(selectIsFullScreenVideo);
  const clientCanControl = useSelector(selectCanControl);
  const meetingID = useSelector(selectMeetingID);

  const dispatch = useDispatch();
  const { trackEvent } = useTrackEvent();

  useEffect(() => {
    if (isFullScreen && userRole === UserRole.THERAPIST) {
      logUsageEvent("TOGGLED_FULLSCREEN_VIDEO_ON", meetingID);
    }
  }, [isFullScreen]);

  const controlDisabled = userRole === UserRole.CLIENT && !clientCanControl;

  const expand = () => {
    if (controlDisabled) {
      return;
    }

    if (!isFullScreen) {
      trackEvent("Video chat - talk mode opened", {
        ["source"]: "expand button",
      });
    } else {
      trackEvent("Video chat - talk mode closed", {
        ["source"]: "expand button",
      });
    }
    dispatch(setIsFullScreenVideo(!isFullScreen));
    setRemoteFullScreenVideoConferencing(!isFullScreen);
  };

  if (controlDisabled) {
    return null;
  }

  return (
    <VideoButton
      className={clsx(className, styles.expandButton)}
      style={style}
      onClick={expand}
      tooltipText={isFullScreen ? "Close Full Screen" : "Expand"}
      forceVisible={!!isFullScreen}
      icon={isFullScreen ? CloseFullscreenIcon : ExpandIcon}
    />
  );
};

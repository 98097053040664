import {
  autoUpdate,
  flip,
  offset,
  shift,
  useClientPoint,
  useFloating,
  useHover,
  useInteractions,
} from "@floating-ui/react";
import { useState } from "react";

export const useExitEditingFloating = () => {
  const [showQuitEditingTooltip, setShowQuitEditingTooltip] = useState(false);

  const { context, refs, floatingStyles } = useFloating({
    open: showQuitEditingTooltip,
    onOpenChange: setShowQuitEditingTooltip,
    placement: "top-start",
    whileElementsMounted: autoUpdate,
    middleware: [offset({ mainAxis: 5, crossAxis: 5 }), flip(), shift()],
  });

  const clientPoint = useClientPoint(context);
  const hover = useHover(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([
    clientPoint,
    hover,
  ]);

  return {
    refs,
    floatingStyles,
    getReferenceProps,
    getFloatingProps,
    showQuitEditingTooltip,
  };
};

import React from "react";
import { fabricTypes } from "utils/fabric-impl";
import { useSelector } from "react-redux";
import {
  selectEditItemId,
  selectIsActivityBankOpen,
} from "redux/editRoomNavigationRedux";

import styles from "./ItemActivityHoverCard.module.css";
import ResourceCategoryIcon from "../../ResourceEntry/ResourceCategoryIcon/ResourceCategoryIcon";
import { FloatingRootContext, ReferenceType } from "@floating-ui/react";
import { ICON_ID } from "../../../itemsConfig";
import clsx from "clsx";
import ResourceThumbnail from "../../ResourceEntry/ResourceThumbnail/ResourceThumbnail";
import { ResourceType } from "../../../utils/drawingUtils";
import { useTooltipFloating } from "../useTooltipFloating";
import { RoomItemFragment } from "generated/graphql";

type ItemActivityHoverCardProps = {
  roomItem: RoomItemFragment;
  thumbnailSrc?: string;
  canvasRef: React.MutableRefObject<fabricTypes.Canvas | undefined>;
  floatingContext: FloatingRootContext<ReferenceType>;
  setCardRef: (node: HTMLElement | null) => void;
  show: boolean;
  cardClass?: string;
};

const ItemActivityHoverCard = ({
  roomItem,
  thumbnailSrc,
  canvasRef,
  floatingContext,
  setCardRef,
  show,
  cardClass,
}: ItemActivityHoverCardProps) => {
  const editItemId = useSelector(selectEditItemId);

  const isActivityBankOpen = useSelector(selectIsActivityBankOpen);

  const resource = roomItem.resource;

  const isPrivateResource = !!resource?.owner_id;

  const iconType = roomItem.icon_id;
  const isPoster = iconType === ICON_ID.POSTER;

  const { floatingStyles } = useTooltipFloating(
    floatingContext,
    iconType,
    canvasRef
  );

  if (!canvasRef.current || !resource || isPoster) {
    return null;
  }

  const categoryIcon =
    resource.categories || (isPrivateResource ? "private" : null);

  const hasSidePanel = isActivityBankOpen || !!editItemId;

  const isNamePhrase = !isPrivateResource;
  return (
    <div
      className={clsx(styles.card, cardClass, {
        [styles.show]: show,
        [styles.scaleUp]: hasSidePanel,
      })}
      ref={setCardRef}
      style={floatingStyles}
    >
      <div className={styles.thumbnailContainer}>
        <ResourceThumbnail
          resourceType={resource.url ? ResourceType.WEBSITE : ResourceType.FILE}
          thumbnailSrc={thumbnailSrc}
        />
      </div>
      <div className={styles.cardBody}>
        <ResourceCategoryIcon
          category={categoryIcon}
          containerClassName={styles.cardIconContainer}
          iconClassName={styles.cardIcon}
        />
        <div
          className={clsx(styles.cardText, {
            [styles.breakAll]: !isNamePhrase,
          })}
        >
          {resource.name}
        </div>
      </div>
    </div>
  );
};

export default ItemActivityHoverCard;

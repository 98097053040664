import React, { useLayoutEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import clsx from "clsx";

import styles from "./TextButton.module.css";
import { COLORS } from "teleoConstants";
import TextSizeMenu from "./TextSizeMenu";
import { ReactComponent as ArrowIcon } from "assets/icons/expand_more.svg";
import { TOOLBAR_HORIZONTAL_PADDING_MULTIPLIER } from "./WhiteboardControls";

export type TextSize = "S" | "M" | "L";

interface TextButtonProps {
  isSelected: boolean;
  buttonStyle: {
    height: number;
    width: number;
    borderRadius: number;
    fontSize: number;
    outlineWidth: number;
  };
  onClick: () => void;
  textSizeRef: React.MutableRefObject<TextSize>;
}

const TextButton = ({
  isSelected,
  buttonStyle,
  onClick,
  textSizeRef,
}: TextButtonProps) => {
  const [size, setSize] = useState<TextSize>("M");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [menuStyles, setMenuStyles] = useState<React.CSSProperties>({});
  const buttonContainerRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);

  const handleClick = () => {
    if (isSelected) {
      setIsMenuOpen(!isMenuOpen);
    } else {
      onClick();
    }
  };

  const handleSubButtonClick = () => {
    setIsMenuOpen(!isMenuOpen);
    onClick();
  };

  const handleArrowButtonClick = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleSizeMenuClick = (size: TextSize) => {
    if (!isSelected) {
      onClick();
    }
    setSize(size);
    textSizeRef.current = size;
    setIsMenuOpen(false);
  };

  useLayoutEffect(() => {
    const buttonRect = buttonRef.current?.getBoundingClientRect();
    if (buttonRect) {
      const containerPadding =
        TOOLBAR_HORIZONTAL_PADDING_MULTIPLIER * buttonStyle.width;
      const menuWidth = buttonStyle.width + 2 * containerPadding;
      setMenuStyles({
        top: buttonRect.top,
        left: buttonRect.left - menuWidth - containerPadding,
        width: menuWidth,
        boxSizing: "border-box",
        padding: containerPadding,
      });
    }
  }, [isMenuOpen, buttonStyle]);

  const menuButtonStyle = {
    ...buttonStyle,
    backgroundColor: COLORS.GRAY,
    outlineWidth: buttonStyle.outlineWidth,
    margin: 0,
  };

  const handleClickOrTapOutside = (event: MouseEvent | TouchEvent) => {
    if (
      buttonContainerRef.current &&
      menuRef.current &&
      !buttonContainerRef.current.contains(event.target as Node) &&
      !menuRef.current.contains(event.target as Node)
    ) {
      setIsMenuOpen(false);
    }
  };

  React.useEffect(() => {
    if (isMenuOpen) {
      document.addEventListener("mousedown", handleClickOrTapOutside);
      document.addEventListener("touchstart", handleClickOrTapOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOrTapOutside);
      document.removeEventListener("touchstart", handleClickOrTapOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOrTapOutside);
      document.removeEventListener("touchstart", handleClickOrTapOutside);
    };
  }, [isMenuOpen]);

  return (
    <div
      ref={buttonContainerRef}
      className={clsx(styles.textButtonContainer, {
        [styles.isSelected]: isSelected,
      })}
    >
      <button
        id={"textButton"}
        className={clsx(styles.button, { [styles.isSelected]: isSelected })}
        style={{ ...buttonStyle, backgroundColor: COLORS.GRAY }}
        ref={buttonRef}
        onClick={handleClick}
      >
        T
      </button>
      <button
        className={clsx(styles.button, styles.subButton, {
          [styles.isSelected]: isSelected,
        })}
        style={{
          ...buttonStyle,
          backgroundColor: COLORS.GRAY,
          width: buttonStyle.width * 0.5,
          height: buttonStyle.height * 0.5,
          fontSize: buttonStyle.fontSize * 0.5,
          outlineWidth: buttonStyle.outlineWidth * 0.7,
        }}
        onClick={handleSubButtonClick}
      >
        {size}
      </button>
      <div
        className={styles.arrowContainer}
        style={{
          left: -1 * ((menuStyles.padding ?? 0) as number),
          width: (menuStyles.padding ?? 0) as number,
        }}
        onClick={handleArrowButtonClick}
      >
        <ArrowIcon
          className={clsx(styles.arrowIcon, {
            [styles.isMenuOpen]: isMenuOpen,
          })}
        />
      </div>

      {isMenuOpen &&
        createPortal(
          <TextSizeMenu
            size={size}
            menuRef={menuRef}
            menuStyles={menuStyles}
            menuButtonStyle={menuButtonStyle}
            handleSizeMenuClick={handleSizeMenuClick}
          />,
          document.body
        )}
    </div>
  );
};

export default TextButton;

import {
  GetOpenRoomModalDataDocument,
  GetRoomMenuDataDocument,
  // eslint-disable-next-line camelcase
  Room_Item_Insert_Input,
  useInsertRoomAndMakeCurrentMutation,
} from "generated/graphql";
import { evictCurrentRoom } from "utils/dbUtils";
import { useDispatch, useSelector } from "react-redux";
import {
  selectOrganizationId,
  selectUserId,
  setCurrentRoomId,
} from "redux/userRedux";
import { setEditItemId } from "redux/editRoomNavigationRedux";
import { selectMeetingID } from "redux/spaceNavigationRedux";
import { logUsageEvent } from "utils/metricsUtils";

export const useCreateRoom = (sendRemoteRoomChanged: () => void) => {
  const [insertRoomAndMakeCurrent] = useInsertRoomAndMakeCurrentMutation();
  const userId = useSelector(selectUserId);
  const organizationId = useSelector(selectOrganizationId);
  const meetingID = useSelector(selectMeetingID);

  const dispatch = useDispatch();

  return {
    createRoom: async (
      roomName: string,
      // eslint-disable-next-line camelcase
      roomItems: Room_Item_Insert_Input[],
      backgroundId: string,
      collectionId: string
    ) => {
      if (!userId || !roomItems || !roomName) return;

      // Create new room in DB
      const { data } = await insertRoomAndMakeCurrent({
        variables: {
          userId,
          roomItems,
          backgroundId,
          collectionId,
          roomName,
          isFirstRoom: false,
        },
        update: evictCurrentRoom(userId),
        refetchQueries: [
          { query: GetRoomMenuDataDocument, variables: { userId } },
          {
            query: GetOpenRoomModalDataDocument,
            variables: {
              userId,
              hasOrganizationId: !!organizationId,
              organizationId,
            },
          },
        ],
      });
      logUsageEvent("ADDED_ROOM", meetingID);
      logUsageEvent("BACKGROUND_CHANGED", meetingID);
      sendRemoteRoomChanged();
      // Update current room
      dispatch(setCurrentRoomId(data?.insert_room_one?.id));
      dispatch(setEditItemId(undefined));
    },
  };
};

import { useEffect } from "react";
import { useSelector } from "react-redux";

import { selectWhiteboardBackground } from "redux/spaceNavigationRedux";
import { useTrackEvent, useTrackEventWithShowHide } from "utils/metricsUtils";

const useTrackWhiteboardEvent = (title: string, defaultProperties?: any) => {
  const isFileActivity = !!useSelector(selectWhiteboardBackground);
  const properties = {
    ...defaultProperties,
    "Is File Activity": isFileActivity,
  };
  const { trackEvent } = useTrackEventWithShowHide(title, properties);

  return { trackEvent, properties };
};

export const useTrackWhiteboardControls = () => {
  const title = "Whiteboard controls";
  const { trackEvent, properties } = useTrackWhiteboardEvent(title);

  const trackControlSelected = (controlType: string) =>
    trackEvent(`${title} - control selected`, {
      ...properties,
      "Control Type": controlType,
    });

  return { trackControlSelected };
};

export const useTrackWhiteboardTextSizeMenu = () => {
  const title = "Whiteboard text size menu";
  const { trackEvent, properties } = useTrackWhiteboardEvent(title);

  const trackSizeSelected = (size: string) =>
    trackEvent(`${title} - size selected`, {
      ...properties,
      "Text Size": size,
    });

  return { trackSizeSelected };
};

export const useTrackWhiteboardCustomColorMenu = (id: string) => {
  const title = "Whiteboard custom color menu";
  const isFileActivity = !!useSelector(selectWhiteboardBackground);
  const properties = { "Color ID": id, "Is File Activity": isFileActivity };
  const { trackEvent } = useTrackEvent();

  const trackMenuOpened = () => trackEvent(`${title} - shown`, properties);
  const trackMenuClosed = () => trackEvent(`${title} - hidden`, properties);
  const trackColorSelected = (color: string) =>
    trackEvent(`${title} - color selected`, { ...properties, Color: color });

  return { trackMenuOpened, trackMenuClosed, trackColorSelected };
};

export const useTrackZoomButton = (zoomLevel: string) => {
  const title = "Whiteboard zoom button";
  const { trackEvent } = useTrackEventWithShowHide(title);

  useEffect(() => {
    trackEvent(`${title} - zoom changed`, { "Zoom Level": zoomLevel });
  }, [zoomLevel]);
};

export const useTrackViewportLockButton = (isViewportLocked: boolean) => {
  const title = "Whiteboard viewport link button";
  const { trackEvent } = useTrackEventWithShowHide(title);

  useEffect(() => {
    trackEvent(`${title} - viewport link changed`, {
      "Viewport Linked": isViewportLocked,
    });
  }, [isViewportLocked]);
};

import { useEffect, useRef } from "react";
import useSound from "use-sound";

import notificationSound from "assets/sounds/notification.mp3";
import { useSelector } from "react-redux";
import { selectClientHasJoinedRoom } from "redux/clientManagementRedux";
import { useFlashTitle } from "pages/Space/hooks/useFlashTitle";
import { useTrackEvent } from "utils/metricsUtils";

const OUT_OF_SESSION_ALERT_INTERVAL = 30 * 1000; // 30 seconds

/**
 * Custom hook that handles notifications for a waiting room scenario.
 *
 * @param {number} alertsCount - The current number of alerts in the waiting room.
 *
 * This hook performs the following actions:
 * - Plays a notification sound and flashes the title when the number of alerts increases.
 * - Sets up an interval to repeatedly trigger alerts if there are waiting room alerts and the client has not joined the room.
 */
export const useWaitingRoomNotification = (alertsCount: number) => {
  const { trackEvent } = useTrackEvent();

  const clientHasJoinedRoom = useSelector(selectClientHasJoinedRoom);
  const previousAlertsCount = useRef<number>(0);

  const [playNotificationSound, { duration }] = useSound(notificationSound);

  const isAudioFileReady = !!duration;

  const hasAnyWaitingRoomAlerts = alertsCount > 0;

  const triggerTitleFlashing = useFlashTitle(
    "Client waiting - Teleo",
    "Waiting room alert banner"
  );

  const triggerAlert = () => {
    trackEvent("Waiting room alert banner - Notification sound played");
    playNotificationSound();
    triggerTitleFlashing();
  };

  useEffect(() => {
    if (!isAudioFileReady || !hasAnyWaitingRoomAlerts || clientHasJoinedRoom) {
      return;
    }

    const intervalId = setInterval(() => {
      triggerAlert();
    }, OUT_OF_SESSION_ALERT_INTERVAL);
    return () => {
      clearInterval(intervalId);
    };
  }, [isAudioFileReady, clientHasJoinedRoom, hasAnyWaitingRoomAlerts]);

  useEffect(() => {
    if (!isAudioFileReady) {
      return;
    }
    const isIncreasing = alertsCount > previousAlertsCount.current;
    if (isIncreasing) {
      triggerAlert();
    }
    previousAlertsCount.current = alertsCount;
  }, [alertsCount, isAudioFileReady]);
};

import { createSlice } from "@reduxjs/toolkit";
import { GetResourcesQuery, Resource } from "generated/graphql";

export type ResourceType = GetResourcesQuery["resource"][number];

export interface EditRoomNavigationState {
  editItemId?: string;
  editItemMoving?: string;
  showAddResourceModal?: boolean;
  deleteItemModalData?: {
    itemId: string;
  };
  showChangeIconModal?: boolean;
  suggestEditModalData?: {
    resource: Resource;
    thumbnailSrc: string | null | undefined;
  };
  sharePubliclyModalData?: {
    resource: Resource;
    thumbnailSrc: string | null | undefined;
  };
  deleteResourceModalData?: {
    resource: Resource;
    thumbnailSrc: string | null | undefined;
  };
  activityBankFilter?: {
    search: string;
    approaches: string[];
    topics: string[];
    types: string[];
    sources: string[];
    ageMin: number;
    ageMax: number;
  };
  isActivityBankOpen: boolean;
  newlyAddedRoomitemId?: string;
  isProviderEditing?: boolean;
}

export interface RootState {
  editRoomNavigation: EditRoomNavigationState;
}

const INITIAL_STATE = {
  isActivityBankOpen: false,
} as Readonly<EditRoomNavigationState>;

export const editRoomNavigationSlice = createSlice({
  name: "editRoomNavigation",
  initialState: {} as EditRoomNavigationState,
  reducers: {
    resetEditRoomNavigation: (state) => {
      return { ...INITIAL_STATE, activityBankFilter: state.activityBankFilter };
    },
    setEditItemId: (
      state,
      { payload }: { payload: EditRoomNavigationState["editItemId"] }
    ) => {
      state.editItemId = payload;
    },
    setEditItemMoving: (
      state,
      { payload }: { payload: EditRoomNavigationState["editItemMoving"] }
    ) => {
      state.editItemMoving = payload;
    },
    setShowAddResourceModal: (state, value) => {
      state.showAddResourceModal = value.payload;
    },
    setDeleteItemModalData: (
      state,
      { payload }: { payload: EditRoomNavigationState["deleteItemModalData"] }
    ) => {
      state.deleteItemModalData = payload;
    },
    setShowChangeIconModal: (state, value) => {
      state.showChangeIconModal = value.payload;
    },
    setSuggestEditModalData: (state, value) => {
      state.suggestEditModalData = value.payload;
    },
    setSharePubliclyModalData: (state, value) => {
      state.sharePubliclyModalData = value.payload;
    },
    setDeleteResourceModalData: (state, value) => {
      state.deleteResourceModalData = value.payload;
    },
    setActivityBankFilter: (
      state,
      { payload }: { payload: EditRoomNavigationState["activityBankFilter"] }
    ) => {
      state.activityBankFilter = payload;
    },
    setIsActivityBankOpen: (
      state,
      { payload }: { payload: EditRoomNavigationState["isActivityBankOpen"] }
    ) => {
      state.isActivityBankOpen = payload;
    },
    setNewlyAddedRoomitemId: (
      state,
      { payload }: { payload: EditRoomNavigationState["newlyAddedRoomitemId"] }
    ) => {
      state.newlyAddedRoomitemId = payload;
    },
    setIsProviderEditing: (
      state,
      { payload }: { payload: EditRoomNavigationState["isProviderEditing"] }
    ) => {
      state.isProviderEditing = payload;
    },
  },
});

export const {
  resetEditRoomNavigation,
  setEditItemId,
  setEditItemMoving,
  setShowAddResourceModal,
  setDeleteItemModalData,
  setShowChangeIconModal,
  setSuggestEditModalData,
  setSharePubliclyModalData,
  setDeleteResourceModalData,
  setActivityBankFilter,
  setIsActivityBankOpen,
  setNewlyAddedRoomitemId,
  setIsProviderEditing,
} = editRoomNavigationSlice.actions;

export const selectEditItemId = (state: RootState) =>
  state.editRoomNavigation.editItemId;
export const selectEditItemMoving = (state: RootState) =>
  state.editRoomNavigation.editItemMoving;
export const selectShowAddResourceModal = (state: RootState) =>
  state.editRoomNavigation.showAddResourceModal;
export const selectDeleteItemModalData = (state: RootState) =>
  state.editRoomNavigation.deleteItemModalData;
export const selectShowChangeIconModal = (state: RootState) =>
  state.editRoomNavigation.showChangeIconModal;
export const selectSuggestEditModalData = (state: RootState) =>
  state.editRoomNavigation.suggestEditModalData;
export const selectSharePubliclyModalData = (state: RootState) =>
  state.editRoomNavigation.sharePubliclyModalData;
export const selectDeleteResourceModalData = (state: RootState) =>
  state.editRoomNavigation.deleteResourceModalData;
export const selectActivityBankFilter = (state: RootState) =>
  state.editRoomNavigation.activityBankFilter;
export const selectIsActivityBankOpen = (state: RootState) =>
  state.editRoomNavigation.isActivityBankOpen;
export const selectNewlyAddedRoomitemId = (state: RootState) =>
  state.editRoomNavigation.newlyAddedRoomitemId;
export const selectIsProviderEditing = (state: RootState) =>
  state.editRoomNavigation.isProviderEditing;

export default editRoomNavigationSlice.reducer;

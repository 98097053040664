import React, {
  ChangeEvent,
  KeyboardEventHandler,
  useEffect,
  useRef,
  useState,
} from "react";

import styles from "./ResourceNameInput.module.css";
import {
  GetResourcesQuery,
  useSetResourceNameMutation,
} from "generated/graphql";
import { truncateResourceName } from "utils/resourceUtils";
import { logUnexpectedError } from "utils/errorUtils";

const MAX_RESOURCE_NAME_LENGTH = 400;

type ResourceEntryProps = {
  resource: GetResourcesQuery["resource"][0];
  renameMode: boolean;
  setRenameMode: (renameMode: boolean) => void;
};

const ResourceNameInput = ({
  resource,
  renameMode,
  setRenameMode,
}: ResourceEntryProps) => {
  const [resourceNameLocal, setResourceNameLocal] = useState(
    truncateResourceName(resource.name, MAX_RESOURCE_NAME_LENGTH)
  );
  const resourceNameInputRef = useRef<HTMLInputElement>(null);

  const [setResourceNameMutation] = useSetResourceNameMutation();

  useEffect(() => {
    if (resource.name) {
      setResourceNameLocal(
        truncateResourceName(resource.name, MAX_RESOURCE_NAME_LENGTH)
      );
    }
  }, [resource.name]);

  useEffect(() => {
    if (renameMode) {
      resourceNameInputRef.current?.select();
    }
  }, [renameMode]);

  const maybeRenameResource = async () => {
    // If the user cleared the input text, or the text is invalid, reset it.
    if (
      !resourceNameLocal ||
      resourceNameLocal.length > MAX_RESOURCE_NAME_LENGTH
    ) {
      if (resource.name) {
        setResourceNameLocal(resource.name);
      }
      return;
    }

    // If the name changed, update the resource name in the db
    if (resourceNameLocal !== resource.name) {
      await setResourceNameMutation({
        variables: {
          id: resource.id,
          name: resourceNameLocal,
        },
      });
    }
  };

  const closeRenameMode = async () => {
    maybeRenameResource().catch(logUnexpectedError);
    setRenameMode(false);
  };

  const resourceNameChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setResourceNameLocal(event.target.value);
  };

  const detectEnterToSubmit: KeyboardEventHandler<HTMLInputElement> = (
    event
  ) => {
    if (event.key === "Enter") {
      closeRenameMode().catch(logUnexpectedError);
    }
  };

  const stopPropagation = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  const onOverlayClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    closeRenameMode().catch(logUnexpectedError);
  };

  return (
    <>
      <div className={styles.overlay} onClick={onOverlayClick} />
      <input
        ref={resourceNameInputRef}
        type="text"
        name="resourceName"
        value={resourceNameLocal || ""}
        className={styles.textInput}
        onChange={resourceNameChangeHandler}
        onKeyDown={detectEnterToSubmit}
        maxLength={MAX_RESOURCE_NAME_LENGTH}
        enterKeyHint={"done"}
        onClick={stopPropagation}
        onDoubleClick={stopPropagation}
      />
    </>
  );
};

export default ResourceNameInput;

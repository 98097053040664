import React from "react";

import styles from "./ExitEditingTooltip.module.css";

import clsx from "clsx";
import { FloatingPortal } from "@floating-ui/react";
import { useExitEditingFloating } from "./useExitEditingFloating";
import { useSelector } from "react-redux";
import {
  selectEditItemId,
  selectIsActivityBankOpen,
} from "redux/editRoomNavigationRedux";

type ExitEditingTooltipProps = {
  exitEditingFloating: ReturnType<typeof useExitEditingFloating>;
  hoverItemId: string | undefined;
  lastHoveredItemId: string | undefined;
};

const ExitEditingTooltip = ({
  exitEditingFloating,
  hoverItemId,
  lastHoveredItemId,
}: ExitEditingTooltipProps) => {
  const editItemId = useSelector(selectEditItemId);
  const isActivityBankOpen = useSelector(selectIsActivityBankOpen);

  if (!exitEditingFloating.showQuitEditingTooltip) {
    return null;
  }

  return (
    <FloatingPortal>
      <div
        ref={exitEditingFloating.refs.setFloating}
        className={clsx(styles.tooltip, {
          [styles.hide]:
            !isActivityBankOpen ||
            !!editItemId ||
            !!hoverItemId ||
            !!lastHoveredItemId,
        })}
        style={exitEditingFloating.floatingStyles}
        {...exitEditingFloating.getFloatingProps()}
      >
        Click to exit
      </div>
    </FloatingPortal>
  );
};

export default ExitEditingTooltip;

import React, { ChangeEvent, useRef, useState } from "react";

import styles from "./AddResourceModal.module.css";
import Button from "components/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  selectBackgroundSize,
  selectMeetingID,
  selectRoomItems,
} from "redux/spaceNavigationRedux";
import {
  selectEditItemId,
  setShowAddResourceModal,
} from "redux/editRoomNavigationRedux";
import Modal from "components/Modal/Modal";
import { uploadFileWithThumbnail } from "utils/fileUtils";
import {
  GetResourcesDocument,
  useInsertResourcesMutation,
  useInsertRoomItemMutation,
  useSetRoomItemMutation,
} from "generated/graphql";
import { selectCurrentRoomId, selectUserId } from "redux/userRedux";
import { v4 as uuid } from "uuid";
import { evictCurrentRoomItems } from "utils/dbUtils";
import Checkbox from "components/Checkbox/Checkbox";
import TextInput from "components/TextInput/TextInput";
import { logUnexpectedError } from "utils/errorUtils";
import clsx from "clsx";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import { getOperationAST } from "graphql";
import { getDefaultConfig, submitPublicResource } from "utils/resourceUtils";
import { logUsageEvent, useLogRoomItemEvent } from "utils/metricsUtils";

const MAX_NUM_FILES = 100;
export const MAX_FILE_SIZE = 100 * 1048576; // 100 MB
const GENERIC_ERROR_MESSAGE =
  "Sorry, there was a problem adding your activity. Please try again later.";

const AddResourceModal = () => {
  const dispatch = useDispatch();
  const editItemId = useSelector(selectEditItemId);
  const [insertResourcesMutation] = useInsertResourcesMutation();
  const [setRoomItemMutation] = useSetRoomItemMutation();
  const [insertRoomItemMutation] = useInsertRoomItemMutation();
  const logRoomItemEvent = useLogRoomItemEvent();
  const roomId = useSelector(selectCurrentRoomId);
  const meetingID = useSelector(selectMeetingID);
  const backgroundSize = useSelector(selectBackgroundSize);
  const roomItems = useSelector(selectRoomItems);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [adding, setAdding] = useState(false);
  const [website, setWebsite] = useState("");
  const userId = useSelector(selectUserId);
  const [fileInputValue, setFileInputValue] = useState<string>("");
  const [selectedFiles, setSelectedFiles] = useState<File[]>();
  const [sharePublicly, setSharePublicly] = useState(false);
  const [shared, setShared] = useState(false);

  const closeModal = () => {
    dispatch(setShowAddResourceModal(false));
  };

  const chooseFile = () => {
    fileInputRef.current?.click();
  };

  const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const selectedFiles = Array.from(event.target.files);
      setSelectedFiles(selectedFiles);
    }
  };

  const insertResourcesAndUpdateRoom = async (
    resourceData: {
      id: string;
      url: string | undefined;
      fileKey: string | undefined;
      thumbnailFileKey: string | undefined;
      selectedFile?: File;
    }[],
    selectedResourceId: string
  ) => {
    if (!roomId || !userId) {
      return false;
    }

    const resources = resourceData.map((datum) => ({
      id: datum.id,
      name: datum.selectedFile?.name ? datum.selectedFile.name : website,
      url: datum.url,
      file_key: datum.fileKey,
      thumbnail_file_key: datum.thumbnailFileKey,
      owner_id: userId,
    }));

    const getResourcesOperationName =
      getOperationAST(GetResourcesDocument)?.name?.value;
    const { errors } = await insertResourcesMutation({
      variables: {
        resources,
      },
      refetchQueries: getResourcesOperationName
        ? [getResourcesOperationName]
        : [],
    });

    if (errors) {
      return false;
    }

    logUsageEvent("ADDED_CUSTOM_ACTIVITY", meetingID);

    if (editItemId) {
      // Set one of the added resources as the item activity
      const editItem = roomItems?.find((item) => item.id === editItemId);
      const iconId = editItem?.icon_id;
      const currentResourceId = editItem?.resource.id;
      const { errors: updateErrors } = await setRoomItemMutation({
        variables: {
          resourceId: selectedResourceId,
          itemId: editItemId,
        },
        update: evictCurrentRoomItems(roomId),
      });
      if (!updateErrors) {
        if (iconId) {
          logRoomItemEvent({
            action: "CHANGE_ACTIVITY",
            iconId,
            resourceId: selectedResourceId,
            previousResourceId: currentResourceId,
          }).catch(logUnexpectedError);
        }
      }
      return !updateErrors;
    } else {
      // Add a new item with this activity
      const selectedResource = resources.find(
        (r) => r.id === selectedResourceId
      );
      if (!selectedResource) {
        return false;
      }
      const defaultConfig = getDefaultConfig(
        null,
        selectedResource.file_key,
        backgroundSize,
        roomItems
      );
      if (!defaultConfig) {
        return false;
      }
      const { errors: updateErrors } = await insertRoomItemMutation({
        variables: {
          roomId,
          resourceId: selectedResourceId,
          ...defaultConfig,
        },
        update: evictCurrentRoomItems(roomId),
      });
      if (!updateErrors) {
        logRoomItemEvent({
          action: "ADD",
          iconId: defaultConfig.iconId,
          resourceId: selectedResourceId,
        }).catch(logUnexpectedError);
      }
      return !updateErrors;
    }
  };

  const fileSizesAreOk = (files: File[]) => {
    for (let i = 0; i < files.length; i++) {
      if (files[i].size > MAX_FILE_SIZE) {
        return false;
      }
    }
    return true;
  };

  const alertAndResetUpload = (message: string) => {
    alert(message);
    setFileInputValue("");
    setSelectedFiles(undefined);
  };

  const uploadFiles = async () => {
    if (!selectedFiles) {
      return false;
    }

    // Check number of files
    if (selectedFiles.length === 0) {
      alertAndResetUpload(`You must select at least one file.`);
      return false;
    }
    if (selectedFiles.length > MAX_NUM_FILES) {
      alertAndResetUpload(
        `Only ${MAX_NUM_FILES} files can be uploaded at a time. Please select fewer files.`
      );
      return false;
    }
    // Check file sizes
    if (!fileSizesAreOk(selectedFiles)) {
      alertAndResetUpload(
        "Files must be 100 MB or smaller. Please ensure all files you have selected are under 100 MB."
      );
      return false;
    }
    // Upload files
    const fileKeys: string[] = [];
    const thumbnailFileKeys: string[] = [];
    for (let i = 0; i < selectedFiles.length; i++) {
      const file = selectedFiles[i];
      const uploadResult = await uploadFileWithThumbnail(file, "activity");
      if (!uploadResult || !uploadResult.key || !uploadResult.thumbnailKey) {
        alertAndResetUpload(GENERIC_ERROR_MESSAGE);
        return false;
      }
      fileKeys.push(uploadResult.key);
      thumbnailFileKeys.push(uploadResult.thumbnailKey);
    }
    // Save resources in DB and select the first resource
    const resourceData = Array(selectedFiles.length)
      .fill("")
      .map((_, index) => ({
        id: uuid(),
        url: undefined,
        fileKey: fileKeys[index],
        thumbnailFileKey: thumbnailFileKeys[index],
        selectedFile: selectedFiles[index],
      }));
    const insertResourcesSuccess = await insertResourcesAndUpdateRoom(
      resourceData,
      resourceData[0].id
    );
    if (!insertResourcesSuccess) {
      alertAndResetUpload(GENERIC_ERROR_MESSAGE);
      return false;
    }
    if (sharePublicly) {
      for (const resource of resourceData) {
        const success = await submitPublicResource(resource.id);
        if (!success) {
          alertAndResetUpload(GENERIC_ERROR_MESSAGE);
          return false;
        }
      }
    }
    return true;
  };

  const addWebsite = async () => {
    // Save resource in DB and select it
    const resourceId = uuid();
    const resourceData = [
      {
        id: resourceId,
        url: website,
        fileKey: undefined,
        thumbnailFileKey: undefined,
      },
    ];
    const insertResourcesSuccess = await insertResourcesAndUpdateRoom(
      resourceData,
      resourceId
    );
    if (!insertResourcesSuccess) {
      throw new Error("insertResources failed");
    }
    if (sharePublicly) {
      const success = await submitPublicResource(resourceId);
      if (!success) {
        throw new Error("submitPublicResource failed");
      }
    }
  };

  const add = async () => {
    try {
      setAdding(true);
      let success = true;
      if (selectedFiles) {
        success = !!(await uploadFiles());
        if (!success) {
          setAdding(false);
          return;
        }
      }
      if (website) {
        await addWebsite();
      }
      if (sharePublicly) {
        setShared(true);
      } else {
        closeModal();
      }
    } catch (err) {
      logUnexpectedError(err);
      alert(GENERIC_ERROR_MESSAGE);
    } finally {
      setAdding(false);
    }
  };

  let numResources = selectedFiles ? selectedFiles.length : 0;
  if (website) {
    numResources += 1;
  }
  const moreThanOneResource = numResources > 1;

  if (shared) {
    return (
      <ConfirmationModal closeModal={closeModal}>
        Thank you for sharing with our clinical team!
        <br />
        We will review your resource{moreThanOneResource ? "s" : ""} and
        possibly incorporate {moreThanOneResource ? "them" : "it"} soon.
      </ConfirmationModal>
    );
  }

  const moreThanOneFile = selectedFiles && selectedFiles.length > 1;
  const selectedFilesHeading = `Selected file${moreThanOneFile ? "s" : ""}:`;
  const selectedFilesName = selectedFiles?.[0]?.name;
  const selectedFilesCount = moreThanOneFile
    ? `and ${selectedFiles.length - 1} more`
    : "";

  return (
    <Modal closeModal={closeModal}>
      <div className={styles.heading}>Add a New Activity</div>
      <div className={styles.body}>
        <div className={styles.side}>
          <div className={styles.sideHeading}>Enter a website</div>
          <TextInput
            placeholder="www.example.com"
            setValue={setWebsite}
            value={website}
            className={styles.textInput}
          />
        </div>
        <div className={styles.divider}>
          <div className={styles.verticalLine} />
          <div className={styles.orText}>OR</div>
          <div className={styles.verticalLine} />
        </div>
        <div className={styles.side}>
          <div className={styles.sideHeading}>Upload an image or worksheet</div>
          <input
            ref={fileInputRef}
            type="file"
            name="file"
            multiple
            style={{ display: "none" }}
            accept=".jpg,.jpeg,.png,.pdf"
            value={fileInputValue}
            onChange={changeHandler}
          />
          <Button
            className={clsx(styles.chooseFileButton, {
              [styles.secondaryButton]: selectedFiles,
            })}
            onClick={chooseFile}
          >
            {selectedFiles ? "Change File(s)" : "Choose File(s)"}
          </Button>
          <div className={styles.textContainer}>
            {selectedFiles ? (
              <>
                <div className={styles.selectedFilesText}>
                  {selectedFilesHeading}
                </div>
                <div className={styles.selectedFilesText}>
                  {selectedFilesName}
                </div>
                <div className={styles.selectedFilesText}>
                  {selectedFilesCount}
                </div>
              </>
            ) : (
              <div className={styles.fileDescriptionText}>
                File types .jpg, .png, and .pdf are supported.
                <br />
                Files must be smaller than 100 MB.
                <br />
                Files should not include PHI.
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={styles.shareSection}>
        <Checkbox
          onToggle={setSharePublicly}
          checked={sharePublicly}
          outlined
          white
        >
          <div className={styles.mainCheckboxText}>
            Share with Teleo clinical advisors for possible inclusion in the
            Activity Bank
          </div>
        </Checkbox>
      </div>
      <div className={styles.buttonRow}>
        <Button className={styles.cancelButton} onClick={closeModal}>
          Cancel
        </Button>
        <Button onClick={add} disabled={(!website && !selectedFiles) || adding}>
          {adding ? "..." : "Add"}
        </Button>
      </div>
    </Modal>
  );
};

export default AddResourceModal;

import { useEffect, useRef, useState } from "react";
import { getAddBreadcrumb, logUnexpectedError } from "utils/errorUtils";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAudioDevices,
  selectIsMuted,
  selectIsNoiseSuppressionEnabled,
  selectIsVideoBlurred,
  selectIsVideoOff,
  selectSelectedAudioDevice,
  selectSelectedVideoDevice,
  selectVideoConferencing,
  selectVideoDevices,
  setAudioDevices,
  setSelectedAudioDevice,
  setSelectedVideoDevice,
  setVideoDevices,
} from "redux/settingsRedux";
import { useBackgroundBlur } from "pages/Space/hooks/video/useBackgroundBlur";
import { selectShowVideoBlurLoading } from "redux/spaceNavigationRedux";
import { isMobileDevice } from "utils/deviceUtils";
import { useStateWithRef } from "hooks/useStateWithRef";
import { useBoxRef } from "hooks/useBoxRef";

const addBreadcrumb = getAddBreadcrumb("userMedia");

export const useUserMedia = () => {
  const selectedAudioDevice = useSelector(selectSelectedAudioDevice);
  const selectedVideoDevice = useSelector(selectSelectedVideoDevice);
  const audioDevices = useSelector(selectAudioDevices);
  const videoDevices = useSelector(selectVideoDevices);
  const isNoiseSuppressionEnabled = useSelector(
    selectIsNoiseSuppressionEnabled
  );

  const dispatch = useDispatch();
  const [localMediaStream, setLocalMediaStream, localMediaStreamRef] =
    useStateWithRef<MediaStream | undefined>(undefined);
  const [userMediaError, setUserMediaError] = useState<string | undefined>(
    undefined
  );
  const [loadingUserMedia, setLoadingUserMedia] = useState<boolean>(false);
  const loadingUserMediaLock = useRef(false);
  const videoConferencing = useSelector(selectVideoConferencing);
  const videoConferencingRef = useRef(videoConferencing);
  useEffect(() => {
    videoConferencingRef.current = videoConferencing;
  }, [videoConferencing]);
  const isMuted = useSelector(selectIsMuted);
  const isMutedRef = useRef(isMuted);
  useEffect(() => {
    isMutedRef.current = isMuted;
  }, [isMuted]);
  const isVideoOff = useSelector(selectIsVideoOff);
  const isVideoOffRef = useBoxRef(isVideoOff);
  const isVideoBlurred = useSelector(selectIsVideoBlurred);
  const showVideoBlurLoading = useSelector(selectShowVideoBlurLoading);

  const { outputStream: blurredLocalMediaStream, ready: blurredStreamIsReady } =
    useBackgroundBlur(localMediaStream);

  const cleanUpStream = () => {
    if (localMediaStreamRef.current) {
      localMediaStreamRef.current.getTracks().forEach((track) => track.stop());
      setUserMediaError("Media Off");
    }
  };

  /**
   * This fetches the media devices available and updates the redux store.
   * Also it chooses a video and audio input device if not already selected,
   * or if the previously selected device is not present anymore.
   *
   * When chosing a device, it will update the redux store.
   *
   */
  const updateAndSelectMediaDevices = async () => {
    let mediaDevices = [];
    try {
      mediaDevices = await navigator.mediaDevices.enumerateDevices();
    } catch (error) {
      logUnexpectedError(error);
      return;
    }

    // Can device.kind be anything else than "audioinput" or "videoinput"?
    const newAudioDevices = mediaDevices.filter(
      (device) => device.kind === "audioinput"
    );
    const newVideoDevices = mediaDevices.filter(
      (device) => device.kind === "videoinput"
    );
    dispatch(setAudioDevices(newAudioDevices));
    dispatch(setVideoDevices(newVideoDevices));

    // reset selected device if it is not available anymore
    if (
      selectedAudioDevice &&
      !newAudioDevices.some(
        (device) => device.deviceId === selectedAudioDevice.deviceId
      )
    ) {
      dispatch(setSelectedAudioDevice(undefined));
    }
    if (
      selectedVideoDevice &&
      !newVideoDevices.some(
        (device) => device.deviceId === selectedVideoDevice.deviceId
      )
    ) {
      dispatch(setSelectedVideoDevice(undefined));
    }
  };

  const getUserMedia = async () => {
    const videoDeviceId = selectedVideoDevice?.deviceId;
    const audioDeviceId = selectedAudioDevice?.deviceId;
    try {
      const supportedConstraints =
        navigator.mediaDevices.getSupportedConstraints();
      const audioConstraints: MediaTrackConstraints = {};

      if (audioDeviceId) {
        audioConstraints.deviceId = audioDeviceId;
      }

      // noise suppression toggle enables echo cancellation as well
      if (supportedConstraints.echoCancellation) {
        audioConstraints.echoCancellation = isNoiseSuppressionEnabled ?? true;
      }
      if (supportedConstraints.noiseSuppression) {
        audioConstraints.noiseSuppression = isNoiseSuppressionEnabled ?? true;
      }

      const constraints: MediaStreamConstraints = {
        video: videoDeviceId ? { deviceId: videoDeviceId } : true,
        audio:
          Object.keys(audioConstraints).length > 0 ? audioConstraints : true,
      };
      addBreadcrumb("info", "getUserMedia constraints", constraints);
      return await navigator.mediaDevices.getUserMedia(constraints);
    } catch (error) {
      logUnexpectedError(
        new Error("Standard user media check failed", { cause: error })
      );
      // Fallback to default constraints
      return await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true,
      });
    }
  };

  const getStream = async () => {
    if (isVideoOff) {
      return;
    }
    if (loadingUserMediaLock.current) {
      return;
    }

    loadingUserMediaLock.current = true;

    try {
      setLoadingUserMedia(true);

      // Cleanup existing stream before requesting a new one
      cleanUpStream();

      // Request permission if this is the first time
      // before the user gives permission, the device list comes with empty strings as IDs
      const stream = await getUserMedia();

      // Fetching media device list on initial load after user gives permission
      if (!localMediaStream) {
        await updateAndSelectMediaDevices();
      }

      if (isMutedRef.current) {
        stream.getAudioTracks().forEach((track) => {
          track.enabled = false;
        });
      }

      if (isVideoOffRef.current) {
        stream.getVideoTracks().forEach((track) => {
          track.enabled = false;
        });
      }

      setLocalMediaStream(stream);
      setUserMediaError(undefined);
      addBreadcrumb("info", "got user media", {
        audioDevice: selectedAudioDevice,
        videoDevice: selectedVideoDevice,
      });
    } catch (error) {
      // log additional debug data
      try {
        const mediaDevices = await navigator.mediaDevices.enumerateDevices();
        addBreadcrumb("info", "media devices", { mediaDevices });
      } catch (innerError) {
        logUnexpectedError(innerError);
      }

      logUnexpectedError(
        new Error("Fallback user media check failed", { cause: error })
      );
      if (error instanceof DOMException) {
        if (error.name === "NotAllowedError") {
          // Rejected or ignored permission
          setUserMediaError("Camera or microphone permission denied");
        } else if (
          error.name === "NotFoundError" ||
          error.name === "OverconstrainedError"
        ) {
          // Missing media track
          setUserMediaError("Could not find camera or microphone");
        } else if (error.name === "NotReadableError") {
          // Hardware error, perhaps in use by another application
          setUserMediaError(
            "Camera is busy with another app. Please close it and retry"
          );
        } else {
          setUserMediaError("An unexpected error occurred");
        }
      } else {
        setUserMediaError("An unexpected error occurred");
      }
    } finally {
      loadingUserMediaLock.current = false;
      setLoadingUserMedia(false);
    }
  };

  useEffect(() => {
    if (videoConferencing) {
      getStream().catch(logUnexpectedError);
    } else {
      cleanUpStream();
      setLocalMediaStream(undefined);
    }
  }, [
    videoConferencing,
    selectedAudioDevice,
    selectedVideoDevice,
    audioDevices,
    videoDevices,
    isNoiseSuppressionEnabled,
  ]);

  useEffect(() => {
    if (localMediaStream) {
      localMediaStream.getAudioTracks().forEach((track) => {
        track.enabled = !isMuted;
      });
    }
  }, [isMuted]);

  useEffect(() => {
    if (localMediaStream) {
      localMediaStream.getVideoTracks().forEach((track) => {
        track.enabled = !isVideoOff;
      });
    }
  }, [isVideoOff]);

  useEffect(() => {
    const updateMediaStream = () => {
      if (videoConferencingRef.current) {
        updateAndSelectMediaDevices().catch(logUnexpectedError);
      }
    };
    navigator.mediaDevices.addEventListener("devicechange", updateMediaStream);

    return () => {
      navigator.mediaDevices.removeEventListener(
        "devicechange",
        updateMediaStream
      );
      cleanUpStream();
    };
  }, []);

  const shouldShowBlurredStream =
    !isMobileDevice() &&
    isVideoBlurred &&
    !showVideoBlurLoading &&
    blurredLocalMediaStream &&
    blurredStreamIsReady;

  return {
    localMediaStream: shouldShowBlurredStream
      ? blurredLocalMediaStream
      : localMediaStream,
    userMediaError,
    closeUserMedia: cleanUpStream,
    reloadUserMedia: getStream,
    loadingUserMedia,
  };
};

import React, { useState } from "react";
import styles from "./CopyButton.module.css";
import Button from "components/Button/Button";
import { ReactComponent as CopyIcon } from "assets/icons/copy.svg";
import { ReactComponent as CheckIcon } from "assets/icons/check.svg";
import clsx from "clsx";
import Tooltip from "components/Tooltip/Tooltip";

type CopyButtonProps = {
  textToCopy: string;
  compact?: boolean;
};

const CopyButton = ({ textToCopy, compact }: CopyButtonProps) => {
  const [copiedLink, setCopiedLink] = useState(false);

  const copy = () => {
    navigator.clipboard.writeText(textToCopy);
    setCopiedLink(true);
    setTimeout(() => setCopiedLink(false), 1000);
  };

  if (compact) {
    const Icon = copiedLink ? CheckIcon : CopyIcon;
    return (
      <Tooltip text={copiedLink ? "Copied" : "Copy Link"}>
        <button className={styles.compactButton} onClick={copy}>
          <Icon id="copyButton" className={styles.compactIcon} />
        </button>
      </Tooltip>
    );
  }

  return (
    <Button onClick={copy}>
      {copiedLink ? (
        <div className={styles.button}>
          <CheckIcon className={clsx(styles.buttonIcon, styles.checkIcon)} />
          Copied
        </div>
      ) : (
        <div className={styles.button}>
          <CopyIcon className={styles.buttonIcon} />
          Copy Link
        </div>
      )}
    </Button>
  );
};

export default CopyButton;

import React, { useState } from "react";
import styles from "./Tooltip.module.css";
import {
  useFloating,
  autoUpdate,
  shift,
  useHover,
  useInteractions,
  Placement,
  flip,
  offset,
  FloatingPortal,
  useDelayGroup,
  useTransitionStyles,
  useDismiss,
} from "@floating-ui/react";
import clsx from "clsx";

type TooltipProps = {
  text: React.ReactNode;
  children: React.ReactNode;
  containerClassName?: string;
  elementWrapperClassName?: string;
  elementWrapperStyle?: React.CSSProperties;
  tooltipClassName?: string;
  placement?: Placement;
  defaultTooltipIsShown?: boolean;
  hoverDelay?: number;
};

// There is an issue when the tooltip is shown and the element moves on screen
// such as in the video buttons when the fullscreen button is toggled.
// For now we may use a key prop to reset the tooltip component,
// but we may try to add a mutation or resize observer to identify when the element moves.
const Tooltip = ({
  text,
  children,
  containerClassName,
  elementWrapperClassName,
  elementWrapperStyle,
  tooltipClassName,
  placement = "top",
  defaultTooltipIsShown = false,
  hoverDelay,
}: TooltipProps) => {
  const [tooltipIsShown, setTooltipIsShown] = useState(defaultTooltipIsShown);
  const { refs, floatingStyles, context } = useFloating({
    open: tooltipIsShown,
    onOpenChange: setTooltipIsShown,
    placement,
    strategy: "fixed",
    whileElementsMounted: autoUpdate,
    middleware: [offset(5), flip(), shift()],
  });

  const { delay, isInstantPhase } = useDelayGroup(context);

  const hover = useHover(context, {
    delay:
      delay ||
      (hoverDelay ?? {
        open: 500,
        close: 100,
      }),
  });

  // Ensure that the tooltip is dismissed if another element is clicked (helpful for touchscreens where "hover" is not
  // detected reliably, and the reference element might become hidden, such as with the video buttons).
  const dismiss = useDismiss(context, { ancestorScroll: true });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
    dismiss,
  ]);

  const { isMounted, styles: tooltipTransitionStyles } = useTransitionStyles(
    context,
    {
      duration: isInstantPhase
        ? {
            open: 0,
            close: 0,
          }
        : {
            open: 200,
            close: 100,
          },
      initial: {
        opacity: 0,
      },
    }
  );

  return (
    <div className={clsx(styles.container, containerClassName)}>
      <div
        className={clsx(styles.childrenContainer, elementWrapperClassName)}
        ref={refs.setReference}
        {...getReferenceProps()}
        style={elementWrapperStyle}
      >
        {children}
      </div>
      {isMounted && !!text && (
        <FloatingPortal>
          <div
            className={clsx(styles.tooltipContainer, tooltipClassName)}
            ref={refs.setFloating}
            style={{ ...floatingStyles, ...tooltipTransitionStyles }}
            {...getFloatingProps()}
          >
            {text}
          </div>
        </FloatingPortal>
      )}
    </div>
  );
};

export default Tooltip;

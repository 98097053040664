import React, {
  MutableRefObject,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import styles from "./WhiteboardControls.module.css";
import ControlButton, {
  ControlButtonConfig,
  ControlButtonType,
} from "./ControlButton";
import { COLORS } from "teleoConstants";
import { fabricTypes } from "utils/fabric-impl";
import { TextSize } from "./TextButton";
import { Peers } from "pages/Space/hooks/connection/usePeerWebRTCConnection";
import { useTrackWhiteboardControls } from "./useWhiteboardAnalytics";

export const TOOLBAR_HORIZONTAL_PADDING_MULTIPLIER = 27 / 70;
export const TOOLBAR_BUTTON_OUTLINE_MULTIPLIER = 1 / 12;

type OptionType = {
  id: string;
  config: ControlButtonConfig;
};

const OPTIONS: OptionType[] = [
  {
    id: "TEXT",
    config: { type: ControlButtonType.TEXT },
  },
  {
    id: "DELETE",
    config: { type: ControlButtonType.DELETE },
  },
  {
    id: "DARK",
    config: { type: ControlButtonType.FREE_DRAW, color: COLORS.DARK },
  },
  {
    id: "GREEN",
    config: { type: ControlButtonType.FREE_DRAW, color: COLORS.GREEN },
  },
  {
    id: "BLUE",
    config: { type: ControlButtonType.FREE_DRAW, color: COLORS.BLUE },
  },
  {
    id: "YELLOW",
    config: { type: ControlButtonType.FREE_DRAW, color: COLORS.YELLOW },
  },
  {
    id: "RED",
    config: { type: ControlButtonType.FREE_DRAW, color: COLORS.RED },
  },
  {
    id: "DIVIDER",
    config: { type: ControlButtonType.DIVIDER },
  },
  {
    id: "CUSTOM_COLOR_1",
    config: { type: ControlButtonType.FREE_DRAW, hasColorPicker: true },
  },
  {
    id: "CUSTOM_COLOR_2",
    config: { type: ControlButtonType.FREE_DRAW, hasColorPicker: true },
  },
];

export const DEFAULT_OPTION = OPTIONS[3];
export const OPTIONS_BY_ID: { [key: string]: ControlButtonConfig } =
  OPTIONS.reduce((prev, value) => ({ ...prev, [value.id]: value.config }), {});

type WhiteboardControlsProps = {
  peersRef: React.MutableRefObject<Peers>;
  peers: Peers;
  canvasRef: React.MutableRefObject<fabricTypes.Canvas | undefined>;
  focusedTextRef: React.MutableRefObject<fabricTypes.IText | undefined>;
  textSizeRef: React.MutableRefObject<TextSize>;
  emitBrushChange: (color: string) => void;
  selectedOptionRef: MutableRefObject<string | undefined>;
  visibleHeight: number;
  setCursorRadius: (radius: number) => void;
  setShowCursor: (show: boolean) => void;
  updateCanvasSize: () => void;
};

const WhiteboardControls = ({
  peersRef,
  peers,
  canvasRef,
  focusedTextRef,
  textSizeRef,
  emitBrushChange,
  selectedOptionRef,
  visibleHeight,
  setCursorRadius,
  setShowCursor,
  updateCanvasSize,
}: WhiteboardControlsProps) => {
  const [selectedOption, setSelectedOption] = useState(DEFAULT_OPTION.id);
  const [buttonHeight, setButtonHeight] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);

  const { trackControlSelected } = useTrackWhiteboardControls();

  useLayoutEffect(() => {
    function updateSize() {
      if (containerRef.current) {
        const numButtons = OPTIONS.length;
        const parentHeight = containerRef.current?.clientHeight || 0;
        const calculatedButtonHeight = parentHeight / (numButtons + 2);
        const newButtonHeight = Math.min(
          Math.max(30, calculatedButtonHeight),
          60
        );
        setButtonHeight(newButtonHeight);
      }
    }
    updateSize();
    window.addEventListener("resize", updateSize);
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  useLayoutEffect(() => {
    updateCanvasSize();
  }, [buttonHeight]);

  const containerStyle = {
    paddingRight: TOOLBAR_HORIZONTAL_PADDING_MULTIPLIER * buttonHeight,
    paddingLeft: TOOLBAR_HORIZONTAL_PADDING_MULTIPLIER * buttonHeight,
  };

  return (
    <div className={styles.controls} ref={containerRef} style={containerStyle}>
      <span /> {/* Spacer between first button and top of container */}
      {OPTIONS.map((o) => (
        <div key={o.id}>
          <ControlButton
            peersRef={peersRef}
            peers={peers}
            id={o.id}
            config={o.config}
            isSelected={o.id === selectedOption}
            setIsSelected={() => {
              trackControlSelected(o.id);
              selectedOptionRef.current = o.id;
              setSelectedOption(o.id);
            }}
            canvasRef={canvasRef}
            containerRef={containerRef}
            focusedTextRef={focusedTextRef}
            textSizeRef={textSizeRef}
            emitBrushChange={emitBrushChange}
            buttonHeight={buttonHeight}
            visibleHeight={visibleHeight}
            setCursorRadius={setCursorRadius}
            setShowCursor={setShowCursor}
          />
        </div>
      ))}
    </div>
  );
};

export default WhiteboardControls;

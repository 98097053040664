import React from "react";
import styles from "./WaitingRoomMenuRow.module.css";
import clsx from "clsx";
import { ReactComponent as XIcon } from "assets/icons/x.svg";
import { ReactComponent as CheckIcon } from "assets/icons/check.svg";
import Tooltip from "components/Tooltip/Tooltip";
import {
  useDismissClient,
  useWaitingRoomAdmitHandler,
} from "utils/waitingRoomUtils";
import { logUnexpectedError } from "utils/errorUtils";
import { Peers } from "pages/Space/hooks/connection/usePeerWebRTCConnection";

type WaitingRoomMenuRowProps = {
  clientId: string;
  name: string;
  closeMenu: () => void;
  peersRef: React.MutableRefObject<Peers>;
};

export const WaitingRoomMenuRow = ({
  clientId,
  name,
  closeMenu,
  peersRef,
}: WaitingRoomMenuRowProps) => {
  const dismissClientOnServer = useDismissClient();
  const dismiss = async () => {
    // Remove from waiting room participants list
    await dismissClientOnServer({ clientId });
  };

  const admit = useWaitingRoomAdmitHandler({
    secretClientId: clientId,
    name,
    peersRef,
  });
  const admitClickHandler = async () => {
    try {
      await admit();
      closeMenu();
    } catch (e) {
      logUnexpectedError(e);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.textContainer}>{name}</div>
      <div className={styles.buttonContainer}>
        <Tooltip text="Remove">
          <button
            className={clsx(styles.button, styles.secondary)}
            onClick={dismiss}
          >
            <XIcon className={styles.buttonIcon} />
          </button>
        </Tooltip>
        <Tooltip text="Admit">
          <button className={styles.button} onClick={admitClickHandler}>
            <CheckIcon className={styles.buttonIcon} />
          </button>
        </Tooltip>
      </div>
    </div>
  );
};

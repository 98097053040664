import {
  useInsertRoomItemMutation,
  useSetRoomItemMutation,
} from "generated/graphql";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectEditItemId,
  setEditItemId,
  setIsActivityBankOpen,
  ResourceType,
  setNewlyAddedRoomitemId,
} from "redux/editRoomNavigationRedux";
import {
  selectBackgroundSize,
  selectRoomItems,
} from "redux/spaceNavigationRedux";
import { selectCurrentRoomId } from "redux/userRedux";
import { evictCurrentRoomItems } from "utils/dbUtils";
import { logUnexpectedError } from "utils/errorUtils";
import { useLogRoomItemEvent } from "utils/metricsUtils";
import { getDefaultConfig } from "utils/resourceUtils";
import ActivityList from "./ActivityList/ActivityList";
import {
  RoomEditingActivityBankActionSourceType,
  useActivityBankEditingAnalytics,
  useRoomEditingCloseActivityBankAnalytics,
} from "../../hooks/useRoomEditingAnalytics";

import { ReactComponent as XIcon } from "assets/icons/x.svg";

import styles from "./ActivityBank.module.css";
import { FloatingDelayGroup } from "@floating-ui/react";

type ActivityBankProps = { sendRemoteRoomItemChanged: () => void };

const ActivityBank = ({ sendRemoteRoomItemChanged }: ActivityBankProps) => {
  const editItemId = useSelector(selectEditItemId);

  const action = !!editItemId ? "EDIT" : "ADD";

  const roomId = useSelector(selectCurrentRoomId);
  const roomItems = useSelector(selectRoomItems);
  const logRoomItemEvent = useLogRoomItemEvent();
  const { trackAddActivity, trackSwitchActivity } =
    useActivityBankEditingAnalytics();
  const { trackCloseActivityBank } = useRoomEditingCloseActivityBankAnalytics();
  const dispatch = useDispatch();
  const [insertRoomItem] = useInsertRoomItemMutation();
  const [setRoomItem] = useSetRoomItemMutation();

  const backgroundImageSize = useSelector(selectBackgroundSize);

  const close = () => {
    dispatch(setIsActivityBankOpen(false));
    dispatch(setEditItemId(undefined));
    trackCloseActivityBank("Close Button");
  };

  const addItem = async (
    resource: ResourceType,
    source: RoomEditingActivityBankActionSourceType
  ) => {
    try {
      const defaultConfig = getDefaultConfig(
        resource.default_icon_id,
        resource.file_key,
        backgroundImageSize,
        roomItems
      );
      if (!roomId || !defaultConfig) {
        return;
      }

      const { errors, data } = await insertRoomItem({
        variables: {
          roomId,
          resourceId: resource.id,
          ...defaultConfig,
        },
        update: evictCurrentRoomItems(roomId),
      });
      sendRemoteRoomItemChanged();

      if (errors) {
        logUnexpectedError(errors);
        alert(
          "Sorry, there was a problem adding the item to your room. Please try again later."
        );
        return;
      }

      dispatch(
        setNewlyAddedRoomitemId(data?.insert_room_item_one?.id ?? undefined)
      );

      trackAddActivity(resource, source);

      logRoomItemEvent({
        action: "ADD",
        iconId: defaultConfig.iconId,
        resourceId: resource.id,
      }).catch(logUnexpectedError);
    } catch (e) {
      logUnexpectedError(e);
      alert(
        "Sorry, there was a problem adding the item to your room. Please try again later."
      );
      return;
    }
  };

  const editItem = roomItems?.find((item) => item.id === editItemId);

  const swapResource = async (
    resource: ResourceType,
    source: RoomEditingActivityBankActionSourceType
  ) => {
    try {
      if (editItemId && roomId && resource && resource.id) {
        const previousResource = editItem?.resource.id;
        const iconId = editItem?.icon_id;
        await setRoomItem({
          variables: {
            resourceId: resource.id,
            itemId: editItemId,
          },
          update: evictCurrentRoomItems(roomId),
        });
        sendRemoteRoomItemChanged();
        trackSwitchActivity(resource, editItem?.resource, source);
        if (iconId) {
          logRoomItemEvent({
            action: "CHANGE_ACTIVITY",
            iconId,
            resourceId: resource.id,
            previousResourceId: previousResource,
          }).catch(logUnexpectedError);
        }
      }
    } catch (err) {
      logUnexpectedError(err);
    }
  };

  return (
    <div className={styles.container}>
      <FloatingDelayGroup
        delay={{
          open: 500,
          close: 500,
        }}
      >
        <div className={styles.headerRow}>
          <div className={styles.header}>Activity Bank</div>
          <XIcon className={styles.xIcon} onClick={close} />
        </div>
        <ActivityList
          onResourceAction={action === "EDIT" ? swapResource : addItem}
          action={action}
          excludePoster={action === "EDIT"}
        />
      </FloatingDelayGroup>
    </div>
  );
};

export default ActivityBank;

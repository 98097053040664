import React, { useRef } from "react";

import styles from "./ItemActivityButtons.module.css";
import clsx from "clsx";
import { ItemActivityButton } from "./ItemActivityButton";
import { ReactComponent as EditIcon } from "assets/icons/edit_line.svg";
import { ReactComponent as RoomItemIcon } from "assets/icons/room_item_icon.svg";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";
import {
  autoUpdate,
  FloatingDelayGroup,
  FloatingRootContext,
  offset,
  ReferenceType,
  shift,
  useFloating,
} from "@floating-ui/react";
import { fabricTypes } from "utils/fabric-impl";
import { useDispatch, useSelector } from "react-redux";
import {
  selectEditItemId,
  selectIsActivityBankOpen,
  setDeleteItemModalData,
  setEditItemId,
  setIsActivityBankOpen,
  setShowChangeIconModal,
} from "redux/editRoomNavigationRedux";
import { ICON_ID } from "../../../itemsConfig";
import { RoomItemFragment } from "generated/graphql";
import {
  useRoomEditingHoverButtonsAnalytics,
  useRoomEditingOpenActivityBankAnalytics,
} from "../../../hooks/useRoomEditingAnalytics";

export type ItemActivityButtonsProps = {
  itemId: string;
  roomItem: RoomItemFragment;
  canvasRef: React.MutableRefObject<fabricTypes.Canvas | undefined>;
  floatingContext: FloatingRootContext<ReferenceType>;
  show: boolean;
  forceExpand: boolean;
  setButtonsRef: (node: HTMLElement | null) => void;
  onRoomItemButtonsMouseEnter: (itemId: string) => void;
  onRoomItemButtonsMouseLeave: (itemId: string) => void;
};

export const ItemActivityButtons = ({
  itemId,
  roomItem,
  canvasRef,
  floatingContext,
  show,
  forceExpand,
  setButtonsRef,
  onRoomItemButtonsMouseEnter,
  onRoomItemButtonsMouseLeave,
}: ItemActivityButtonsProps) => {
  const dispatch = useDispatch();

  const editItemId = useSelector(selectEditItemId);
  const isActivityBankOpen = useSelector(selectIsActivityBankOpen);

  const {
    trackChangeIconClick,
    trackDeleteClick,
    trackEditClick,
    trackEditPosterClick,
  } = useRoomEditingHoverButtonsAnalytics();

  const { trackOpenActivityBank } = useRoomEditingOpenActivityBankAnalytics();

  const menuContainerRef = useRef<HTMLDivElement | null>(null);

  const iconType = roomItem.icon_id;
  const isPoster = iconType === ICON_ID.POSTER;

  const { floatingStyles } = useFloating({
    rootContext: floatingContext,
    open: true,
    placement: isPoster ? "bottom-end" : "top-end",
    strategy: "absolute",
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(({ rects }) => ({
        mainAxis: -0.3 * rects.floating.width,
        crossAxis: 0.7 * rects.floating.height,
      })),
      shift({
        mainAxis: true,
        crossAxis: true,
        padding: 5,
        boundary: canvasRef.current?.getElement(),
      }),
    ],
  });

  const handleEditClick = () => {
    if (isPoster) {
      trackEditPosterClick(editItemId === itemId);
    } else {
      trackEditClick(roomItem.resource, editItemId === itemId);
    }

    if (editItemId === itemId) {
      return;
    }

    dispatch(setIsActivityBankOpen(true));
    dispatch(setEditItemId(itemId));

    if (!isActivityBankOpen) {
      trackOpenActivityBank("Edit Item Hover Button");
    }
  };

  const handleSwitchActivityIconClick = () => {
    dispatch(setShowChangeIconModal(true));
    dispatch(setEditItemId(itemId));
    trackChangeIconClick(roomItem.resource);
  };

  const handleDeleteItemClick = () => {
    dispatch(setEditItemId(itemId));
    dispatch(setDeleteItemModalData({ itemId }));
    trackDeleteClick(roomItem.resource);
  };

  const handleMouseEnter = () => {
    onRoomItemButtonsMouseEnter(itemId);
  };
  const handleMouseLeave = () => {
    onRoomItemButtonsMouseLeave(itemId);
  };

  return (
    <div
      className={styles.containerAnchor}
      ref={setButtonsRef}
      style={floatingStyles}
    >
      <div className={styles.containerAnchorRelative} ref={menuContainerRef}>
        <div
          className={clsx(styles.container, {
            [styles.show]: show,
            [styles.forceExpand]: forceExpand,
          })}
          data-room-item-button-id={itemId}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <FloatingDelayGroup
            delay={{
              open: 500,
              close: 100,
            }}
          >
            <ItemActivityButton
              className={styles.primaryButton}
              onClick={handleEditClick}
              tooltip={isPoster ? "Change poster" : "Switch activity"}
            >
              <EditIcon className={styles.buttonIcon} />
            </ItemActivityButton>
            {!isPoster && (
              <ItemActivityButton
                onClick={handleSwitchActivityIconClick}
                className={styles.secondaryButton}
                tooltip="Change icon"
              >
                <RoomItemIcon className={styles.buttonIcon} />
              </ItemActivityButton>
            )}
            <ItemActivityButton
              onClick={handleDeleteItemClick}
              className={styles.secondaryButton}
              red
              tooltip="Delete item"
            >
              <TrashIcon className={styles.buttonIcon} />
            </ItemActivityButton>
          </FloatingDelayGroup>
        </div>
      </div>
    </div>
  );
};

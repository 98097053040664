import React, { useState } from "react";

import styles from "./DeleteResourceModal.module.css";
import Button from "components/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  selectDeleteResourceModalData,
  setDeleteResourceModalData,
} from "redux/editRoomNavigationRedux";
import Modal from "components/Modal/Modal";
import { selectCurrentRoomId } from "redux/userRedux";
import { evictCurrentRoomItems } from "utils/dbUtils";
import { logUnexpectedError } from "utils/errorUtils";
import {
  GetResourcesDocument,
  useDeleteResourceMutation,
} from "generated/graphql";
import { getOperationAST } from "graphql";
import ResourceEntry from "pages/Space/subpages/SpaceRoom/components/ResourceEntry/ResourceEntry";

const GENERIC_ERROR_MESSAGE =
  "Sorry, there was a problem deleting your resource. Please try again later.";

const DeleteResourceModal = () => {
  const dispatch = useDispatch();
  const modalData = useSelector(selectDeleteResourceModalData);
  const roomId = useSelector(selectCurrentRoomId);
  const [deleteResourceMutation] = useDeleteResourceMutation();
  const [deleting, setDeleting] = useState(false);

  const resource = modalData?.resource;
  const thumbnailSrc = modalData?.thumbnailSrc;

  const closeModal = () => {
    dispatch(setDeleteResourceModalData(null));
  };

  const deleteResource = async () => {
    try {
      setDeleting(true);
      if (!resource || !roomId) {
        return;
      }

      const getResourcesOperationName =
        getOperationAST(GetResourcesDocument)?.name?.value;
      const { errors } = await deleteResourceMutation({
        variables: {
          resourceId: resource?.id,
        },
        update: evictCurrentRoomItems(roomId),
        refetchQueries: getResourcesOperationName
          ? [getResourcesOperationName]
          : [],
      });
      if (errors) {
        logUnexpectedError(errors);
        alert(GENERIC_ERROR_MESSAGE);
        return;
      }

      closeModal();
    } catch (e) {
      logUnexpectedError(e);
      alert(GENERIC_ERROR_MESSAGE);
    } finally {
      setDeleting(false);
    }
  };

  return (
    <Modal closeModal={closeModal}>
      <div className={styles.heading}>Delete Activity</div>
      <div className={styles.body}>
        {resource ? (
          <ResourceEntry
            resource={resource}
            thumbnailSrc={thumbnailSrc}
            displayOnly
          />
        ) : null}
        <div className={styles.text}>
          Are you sure you want to delete this activity, and remove it from all
          rooms?
        </div>
      </div>
      <div className={styles.buttonRow}>
        <Button className={styles.cancelButton} onClick={closeModal}>
          Cancel
        </Button>
        <Button onClick={deleteResource} disabled={deleting}>
          {deleting ? "..." : "Delete"}
        </Button>
      </div>
    </Modal>
  );
};

export default DeleteResourceModal;

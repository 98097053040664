import { fabric } from "utils/fabricUtils";
import { fabricTypes } from "./fabric-impl";
import { loadPdfPageCanvasWithData } from "./pdfUtils";
import { isImageFile, isPdfFile } from "./fileUtils";
import { clearFabricCanvas } from "./canvasUtils";

const THUMBNAIL_SIZE = 300;

const sleep = (delay: number) =>
  new Promise((resolve) => setTimeout(resolve, delay));

export const loadDataAsDataURL = (blob: Blob) => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = function (f) {
      const data = f.target?.result;
      if (!data || typeof data !== "string") {
        reject(new Error("Data not loaded"));
      } else {
        resolve(data);
      }
    };
    reader.readAsDataURL(blob);
  });
};

const loadDataAsArrayBuffer = (file: File) => {
  return new Promise<ArrayBuffer>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = function (f) {
      const data = f.target?.result;
      if (!data || typeof data !== "object") {
        reject(new Error("Data not loaded"));
      } else {
        resolve(data);
      }
    };
    reader.readAsArrayBuffer(file);
  });
};

const loadImage = (data: string) => {
  return new Promise<fabricTypes.Image>((resolve) => {
    fabric.Image.fromURL(
      data,
      function (oImg: fabricTypes.Image) {
        resolve(oImg);
      },
      { crossOrigin: "anonymous" }
    ); // Required to be able to call toDataURL on any canvas an external image is added to, for example to copy from a temporary canvas
  });
};

const generateImageForImage = async (file: File) => {
  const data = await loadDataAsDataURL(file);
  return await loadImage(data);
};

const generateImageForPdf = async (file: File) => {
  const data = await loadDataAsArrayBuffer(file);
  const typedArray = new Uint8Array(data);
  const pdfPageCanvas = await loadPdfPageCanvasWithData(typedArray, 1);
  return new fabric.Image(pdfPageCanvas);
};

const generateThumbnailCanvasForFile = async (
  file: File,
  crop: boolean = true
) => {
  const filename = file.name;
  let img;
  if (isPdfFile(filename)) {
    img = await generateImageForPdf(file);
  } else if (isImageFile(filename)) {
    img = await generateImageForImage(file);
    // Workaround for bug in Safari where the generated thumbnail is occasionally blank.
    // In this case, we can see that generateImageForImage > loadDataAsDataURL returns non-blank data,
    // but generateImageForImage > `await loadImage(data)` is blank immediately after returning.
    // Possibly related report: https://github.com/fabricjs/fabric.js/issues/7054 (unresolved)
    // FabricJS version 6 is switching from callbacks to Promises, which may fix this in the future.
    // Note: 50ms was not enough to fix the issue, but 100ms seems to be enough.
    await sleep(100);
  } else {
    throw new Error("Unsupported file type");
  }

  return crop
    ? generateThumbnailCanvasForImage(img)
    : generateThumbnailCanvasForImageWithoutCrop(img);
};

const generateThumbnailCanvasForImageWithoutCrop = async (
  img: fabricTypes.Image
) => {
  if (!img || !img.width || !img.height) {
    throw new Error("Problem loading file");
  }

  const fitTo = img.width > img.height ? "height" : "width";

  const scaleRatio =
    fitTo === "height"
      ? THUMBNAIL_SIZE / img.height
      : THUMBNAIL_SIZE / img.width;

  const canvas = new fabric.StaticCanvas(
    null,
    fitTo === "height"
      ? {
          height: THUMBNAIL_SIZE,
          width: img.width * scaleRatio,
        }
      : {
          height: img.height * scaleRatio,
          width: THUMBNAIL_SIZE,
        }
  );

  img.set({
    scaleX: scaleRatio,
    scaleY: scaleRatio,
    srcFromAttribute: true,
  });
  canvas.add(img);
  return canvas;
};

const generateThumbnailCanvasForImage = async (img: fabricTypes.Image) => {
  const canvas = new fabric.StaticCanvas(null, {
    height: THUMBNAIL_SIZE,
    width: THUMBNAIL_SIZE,
  });

  if (!img || !img.width || !img.height) {
    throw new Error("Problem loading file");
  }

  const scaleRatio =
    img.width < img.height
      ? THUMBNAIL_SIZE / img.width
      : THUMBNAIL_SIZE / img.height;
  const fullWidth = img.width * scaleRatio;
  const xOffset =
    img.width < img.height ? 0 : (-1 * (fullWidth - THUMBNAIL_SIZE)) / 2;

  img.set({
    left: xOffset,
    scaleX: scaleRatio,
    scaleY: scaleRatio,
    srcFromAttribute: true,
  });
  canvas.add(img);
  return canvas;
};

export const dataURLtoBlob = (dataURL: string, options: BlobPropertyBag) => {
  const binary = window.atob(dataURL.split(",")[1]);
  const array = [];
  for (let i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i));
  }
  return new Blob([new Uint8Array(array)], options);
};

const generateThumbnailForCanvas = async (canvas: fabricTypes.StaticCanvas) => {
  const dataURL = canvas?.toDataURL({ format: "png" });
  if (!dataURL) {
    return;
  }
  const blob = dataURLtoBlob(dataURL, { type: "image/png" });

  // Clear canvas contents when done to work around Safari canvas caching issues
  clearFabricCanvas(canvas);

  return blob;
};

export const generateThumbnailForFile = async (
  file: File,
  crop: boolean = true
) => {
  const canvas = await generateThumbnailCanvasForFile(file, crop);
  return generateThumbnailForCanvas(canvas);
};

export const generateThumbnailForImage = async (img: fabricTypes.Image) => {
  const canvas = await generateThumbnailCanvasForImage(img);
  return generateThumbnailForCanvas(canvas);
};

import React from "react";

import styles from "./ItemActivityButton.module.css";
import clsx from "clsx";
import Tooltip from "components/Tooltip/Tooltip";

export type ItemActivityButtonProps = {
  children: React.ReactNode;
  onClick?: () => void;
  className?: string;
  red?: boolean;
  tooltip: string;
};

export const ItemActivityButton = ({
  children,
  onClick,
  className,
  red,
  tooltip,
}: ItemActivityButtonProps) => {
  return (
    <Tooltip text={tooltip}>
      <button
        className={clsx(styles.button, className, { [styles.red]: red })}
        onClick={onClick}
      >
        {children}
      </button>
    </Tooltip>
  );
};

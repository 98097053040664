import React, { useRef, useState } from "react";

import styles from "./ResourceEntry.module.css";
import clsx from "clsx";
import ResourceThumbnail from "./ResourceThumbnail/ResourceThumbnail";
import ResourceMetadata, {
  ResourceMetadataType,
} from "./ResourceMetadata/ResourceMetadata";
import ResourceCategoryIcon from "./ResourceCategoryIcon/ResourceCategoryIcon";
import ResourceAction from "./ResourceActionMenu/ResourceActionMenu";
import { ReactComponent as LockIcon } from "assets/icons/lock.svg";
import { ReactComponent as PlusIcon } from "assets/icons/plus.svg";
import { ReactComponent as CheckIcon } from "assets/icons/check.svg";
import Tooltip from "components/Tooltip/Tooltip";
import { getOrganizationConfig } from "utils/organizationUtils";
import { logUnexpectedError } from "utils/errorUtils";
import ResourceNameInput from "./ResourceNameInput/ResourceNameInput";
import { ResourceType as DrawingResourceType } from "../../utils/drawingUtils";
import { ResourceType } from "redux/editRoomNavigationRedux";
import { RoomEditingActivityBankActionSourceType } from "../../hooks/useRoomEditingAnalytics";

type ResourceEntryProps = {
  resource: ResourceType;
  thumbnailSrc: string | null | undefined;
  isFavoriteRow?: boolean;
  isSharedRoomRow?: boolean;
  displayOnly?: boolean;
  onResourceAction?: (
    resource: ResourceType,
    source: RoomEditingActivityBankActionSourceType
  ) => Promise<void>;
  action?: "ADD" | "EDIT";
  selected?: boolean;
};

const ResourceEntry = ({
  resource,
  thumbnailSrc,
  isFavoriteRow,
  isSharedRoomRow,
  displayOnly,
  onResourceAction,
  action,
  selected,
}: ResourceEntryProps) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [renameMode, setRenameMode] = useState(false);
  const [hasTapped, setHasTapped] = useState(false);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const startedTouchRef = useRef(false);

  const triggerAction = async (
    source: RoomEditingActivityBankActionSourceType
  ) => {
    try {
      if (displayOnly || !onResourceAction) {
        return;
      }
      await onResourceAction(resource, source);
    } catch (err) {
      logUnexpectedError(err);
    }
  };

  const attribution = resource.attribution;
  const resourceType = resource.file_key
    ? DrawingResourceType.FILE
    : resource.url
    ? DrawingResourceType.WEBSITE
    : DrawingResourceType.FILE;

  const hasMetadata =
    resource.approaches?.length > 0 ||
    resource.age_min ||
    resource.age_max ||
    resource.topics?.length > 0;
  const isCustom =
    (!!resource.owner_id && !resource.organization_id) ||
    (!!resource.organization_id && !!resource.shared_type);
  const organizationConfig = getOrganizationConfig(resource.organization_id);

  const canClickRow = !displayOnly && action === "EDIT";

  const touchStartHandler = () => {
    startedTouchRef.current = true;
  };

  const clickRowHandler = () => {
    if (startedTouchRef.current && !hasTapped) {
      startedTouchRef.current = false;
      setHasTapped(true);
      const clearTouchHandler = (event: TouchEvent) => {
        if (
          event.target === null ||
          !(event.target instanceof HTMLElement) ||
          !containerRef.current
        ) {
          setHasTapped(false);
          window.removeEventListener("touchend", clearTouchHandler);
          return;
        }

        const target = event.target;
        const isClickInsideContainer = containerRef.current.contains(target);
        if (!isClickInsideContainer) {
          window.removeEventListener("touchend", clearTouchHandler);
          setHasTapped(false);
        }
      };
      window.addEventListener("touchend", clearTouchHandler);
      return;
    }

    if (canClickRow) {
      triggerAction("row");
    }
  };

  return (
    <div
      className={clsx(styles.container, {
        [styles.custom]: isCustom,
        [styles.menuOpen]: menuIsOpen,
        [styles.displayOnly]: displayOnly,
        [styles.sharedRoom]: isSharedRoomRow,
        [styles.selected]: selected,
        [styles.tapped]: hasTapped,
        [styles.clickable]: canClickRow,
      })}
      onTouchStart={touchStartHandler}
      onClick={clickRowHandler}
      ref={containerRef}
    >
      <ResourceThumbnail
        thumbnailSrc={thumbnailSrc}
        resourceType={resourceType}
        className={styles.resourceThumbnail}
      />
      <div className={styles.rightSection}>
        <div
          className={clsx(styles.row, styles.headerRow, {
            [styles.headerRowLarge]: hasMetadata,
          })}
        >
          <div className={styles.headerAndIcons}>
            {isCustom && (
              <div className={styles.iconContainer}>
                <Tooltip text="Private">
                  <LockIcon className={styles.lockIcon} />
                </Tooltip>
              </div>
            )}
            {!isCustom && organizationConfig?.hasCustomActivityBank && (
              <div className={styles.iconContainer}>
                <Tooltip text={organizationConfig.name}>
                  <img
                    className={styles.organizationIcon}
                    src={organizationConfig?.icon}
                  />
                </Tooltip>
              </div>
            )}
            {renameMode ? (
              <ResourceNameInput
                resource={resource}
                renameMode={renameMode}
                setRenameMode={setRenameMode}
              />
            ) : (
              <Tooltip text={resource.name}>
                <div
                  className={clsx(styles.header, {
                    [styles.headerSharedRoom]: isSharedRoomRow,
                  })}
                >
                  {resource.name}
                </div>
              </Tooltip>
            )}
            {!isFavoriteRow && (
              <>
                <ResourceCategoryIcon
                  category={resource.categories}
                  containerClassName={styles.resourceCategoryIcon}
                />
                {resource.is_psychoeducational ? (
                  <ResourceCategoryIcon
                    category="Psychoeducational"
                    containerClassName={styles.resourceCategoryIcon}
                  />
                ) : null}
              </>
            )}
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.attribution}>
            {attribution ? `By ${attribution}` : ""}
          </div>
        </div>
        {hasMetadata ? (
          <div className={styles.row}>
            <ResourceMetadata
              metadataType={ResourceMetadataType.APPROACH}
              value={resource.approaches}
              width={1.2}
            />
            {!isFavoriteRow && (
              <>
                <ResourceMetadata
                  metadataType={ResourceMetadataType.AGE}
                  value={{
                    ageMin: resource.age_min,
                    ageMax: resource.age_max,
                  }}
                  width={0.8}
                />
                {!isSharedRoomRow && (
                  <ResourceMetadata
                    metadataType={ResourceMetadataType.TOPIC}
                    value={resource.topics}
                    width={2}
                  />
                )}
              </>
            )}
          </div>
        ) : null}
      </div>

      {!displayOnly && (
        <Tooltip
          text={action === "ADD" ? "Add to room" : "Switch activity"}
          containerClassName={styles.actionButtonContainer}
        >
          <button
            className={styles.actionButton}
            onClick={() => triggerAction("button")}
          >
            {action === "ADD" ? (
              <PlusIcon className={clsx(styles.plusIcon, styles.actionIcon)} />
            ) : (
              <CheckIcon className={styles.actionIcon} />
            )}
          </button>
        </Tooltip>
      )}
      {displayOnly ? null : (
        <ResourceAction
          resource={resource}
          thumbnailSrc={thumbnailSrc}
          menuIsOpen={menuIsOpen}
          isFavoriteRow={isFavoriteRow}
          setMenuIsOpen={setMenuIsOpen}
          setRenameMode={() => setRenameMode(true)}
        />
      )}
    </div>
  );
};

export default ResourceEntry;

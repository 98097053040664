import React from "react";
import clsx from "clsx";

import styles from "./TextSizeMenu.module.css";
import { TextSize } from "./TextButton";
import { useTrackWhiteboardTextSizeMenu } from "./useWhiteboardAnalytics";

interface TextSizeMenuProps {
  size: TextSize;
  menuRef: React.RefObject<HTMLDivElement>;
  menuStyles: React.CSSProperties;
  menuButtonStyle: {
    backgroundColor: string;
    outlineWidth: number;
    margin: number;
    height: number;
    width: number;
    borderRadius: number;
    fontSize: number;
  };
  handleSizeMenuClick: (size: TextSize) => void;
}

const TextSizeMenu = ({
  size,
  menuRef,
  menuStyles,
  menuButtonStyle,
  handleSizeMenuClick,
}: TextSizeMenuProps) => {
  const { trackSizeSelected } = useTrackWhiteboardTextSizeMenu();

  const handleSizeButtonClick = (size: TextSize) => {
    trackSizeSelected(size);
    handleSizeMenuClick(size);
  };

  return (
    <div ref={menuRef} className={styles.menu} style={menuStyles}>
      <div
        className={clsx(styles.button, styles.menuOption, {
          [styles.isSelected]: size === "S",
        })}
        style={{
          ...menuButtonStyle,
          fontSize: menuButtonStyle.fontSize * 0.6,
        }}
        onClick={() => handleSizeButtonClick("S")}
      >
        S
      </div>
      <div
        className={clsx(styles.button, styles.menuOption, {
          [styles.isSelected]: size === "M",
        })}
        style={{
          ...menuButtonStyle,
          fontSize: menuButtonStyle.fontSize * 0.8,
        }}
        onClick={() => handleSizeButtonClick("M")}
      >
        M
      </div>
      <div
        className={clsx(styles.button, styles.menuOption, {
          [styles.isSelected]: size === "L",
        })}
        style={{
          ...menuButtonStyle,
          fontSize: menuButtonStyle.fontSize * 1.3,
        }}
        onClick={() => handleSizeButtonClick("L")}
      >
        L
      </div>
    </div>
  );
};

export default TextSizeMenu;

import React, { useState } from "react";
import { RoomItemFragment } from "generated/graphql";
import {
  generateStyleForItem,
  useRoomItemOverlaysIDs,
} from "../../hooks/useRoomItemOverlays";
import { fabricTypes } from "utils/fabric-impl";
import ItemActivityHoverCard from "./ItemActivityHoverCard/ItemActivityHoverCard";
import { useFloatingRootContext } from "@floating-ui/react";

import styles from "./RoomItemOverlay.module.css";
import ItemActivityTooltip from "./ItemActivityTooltip/ItemActivityTooltip";
import { useSelector } from "react-redux";
import { selectEnableResourceNameViewer } from "redux/settingsRedux";
import clsx from "clsx";
import { selectUserRole, UserRole } from "redux/userRedux";
import {
  selectIsActivityBankOpen,
  selectNewlyAddedRoomitemId,
} from "redux/editRoomNavigationRedux";
import { ItemActivityButtons } from "./ItemActivityButtons/ItemActivityButtons";
import { ICON_ID } from "../../itemsConfig";

type RoomItemOverlayProps = {
  roomItem: RoomItemFragment;
  thumbnailSrc?: string;
  backgroundImageSizeRef: React.MutableRefObject<
    { width: number; height: number } | undefined
  >;
  canvasRef: React.MutableRefObject<fabricTypes.Canvas | undefined>;
  editItemId?: string;
  editItemMoving?: string;
  isHovering?: boolean;
  isLongPressing: boolean;
  onRoomItemButtonsMouseEnter: (itemId: string) => void;
  onRoomItemButtonsMouseLeave: (itemId: string) => void;
};

const useDataResourceName = (roomItem: RoomItemFragment) => {
  const { fileActivityItemId, websiteActivityItemId, albumItemId } =
    useRoomItemOverlaysIDs();

  switch (roomItem.id) {
    case fileActivityItemId:
      return "feelingsChartOverlay";
    case websiteActivityItemId:
      return "pencilCupOverlay";
    case albumItemId:
      return "albumOverlay";
  }
};

export const RoomItemOverlay = ({
  roomItem,
  thumbnailSrc,
  backgroundImageSizeRef,
  canvasRef,
  editItemId,
  editItemMoving,
  isHovering,
  isLongPressing,
  onRoomItemButtonsMouseEnter,
  onRoomItemButtonsMouseLeave,
}: RoomItemOverlayProps) => {
  const [floatingReferenceElement, setFloatingReferenceElement] =
    useState<HTMLDivElement | null>(null);
  const [cardElement, setCardElement] = useState<HTMLElement | null>(null);
  const [tooltipElement, setTooltipElement] = useState<HTMLElement | null>(
    null
  );
  const [buttonsElement, setButtonsElement] = useState<HTMLElement | null>(
    null
  );

  const newlyAddedRoomitemId = useSelector(selectNewlyAddedRoomitemId);
  const isThisItemNewlyAdded = roomItem.id === newlyAddedRoomitemId;

  const isResourceNameViewerEnabled = !!useSelector(
    selectEnableResourceNameViewer
  );

  const isProvider = useSelector(selectUserRole) === UserRole.THERAPIST;

  const isActivityBankOpen = useSelector(selectIsActivityBankOpen);

  const dataResourceName = useDataResourceName(roomItem);

  const isEditingThisItem = !!editItemId && roomItem.id === editItemId;

  const showCard =
    !!isHovering || isLongPressing || isEditingThisItem || isThisItemNewlyAdded;

  const showTooltip = !showCard && isResourceNameViewerEnabled && !editItemId;

  const iconType = roomItem.icon_id;
  const isPoster = iconType === ICON_ID.POSTER;

  const cardFloatingContext = useFloatingRootContext({
    open: true,
    elements: {
      reference: floatingReferenceElement,
      floating: cardElement,
    },
  });

  const tooltipFloatingContext = useFloatingRootContext({
    open: true,
    elements: {
      reference: floatingReferenceElement,
      floating: tooltipElement,
    },
  });

  const buttonsFloatingContext = useFloatingRootContext({
    open: true,
    elements: {
      reference: isPoster ? floatingReferenceElement : cardElement,
      floating: buttonsElement,
    },
  });

  return (
    <div
      key={roomItem.id}
      id={`room-item-overlay-${roomItem.id}`}
      data-resource-name={dataResourceName}
      className={clsx(styles.roomItemOverlayContainer, {
        [styles.isEditing]: isEditingThisItem,
        [styles.isHovering]: isHovering,
        [styles.isLongPressing]: isLongPressing,
        [styles.newlyAddedItem]: isThisItemNewlyAdded,
      })}
      style={{
        ...generateStyleForItem(roomItem, backgroundImageSizeRef),
      }}
    >
      <div
        className={styles.floatingReference}
        ref={setFloatingReferenceElement}
      ></div>
      <div className={styles.floatingScaleContainer}>
        {isProvider && editItemMoving !== roomItem.id && (
          <ItemActivityHoverCard
            key={`card-${roomItem.id}`}
            roomItem={roomItem}
            thumbnailSrc={thumbnailSrc}
            show={showCard}
            canvasRef={canvasRef}
            floatingContext={cardFloatingContext}
            setCardRef={setCardElement}
            cardClass={styles.card}
          />
        )}
        {isProvider && !(editItemMoving && isHovering) && (
          <ItemActivityTooltip
            key={`tooltip-${roomItem.id}`}
            roomItem={roomItem}
            canvasRef={canvasRef}
            floatingContext={tooltipFloatingContext}
            show={showTooltip}
            setTooltipRef={setTooltipElement}
          />
        )}
        {isProvider && !editItemMoving && (
          <ItemActivityButtons
            show={!!isHovering || isLongPressing || isEditingThisItem}
            forceExpand={
              isActivityBankOpen || isEditingThisItem || isLongPressing
            }
            itemId={roomItem.id}
            roomItem={roomItem}
            canvasRef={canvasRef}
            floatingContext={buttonsFloatingContext}
            setButtonsRef={setButtonsElement}
            onRoomItemButtonsMouseEnter={onRoomItemButtonsMouseEnter}
            onRoomItemButtonsMouseLeave={onRoomItemButtonsMouseLeave}
          />
        )}
      </div>
    </div>
  );
};

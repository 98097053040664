import React, { useState } from "react";
import { v4 as uuid } from "uuid";

import styles from "./SharedRoomFolderModal.module.css";
import Button from "components/Button/Button";
import Modal from "components/Modal/Modal";
import {
  GetOpenRoomModalDataDocument,
  GetRoomMenuDataDocument,
  Room_Item_Insert_Input as RoomItemInsertInput,
  useCreateRoomMutation,
  useGetSharedRoomFolderModalDataQuery,
  useInsertAnalyticsBackgroundUpdateMutation,
  useSetCurrentRoomMutation,
} from "generated/graphql";
import SharedRoomRow from "./SharedRoomRow";
import { useDispatch, useSelector } from "react-redux";
import {
  selectMeetingID,
  selectSharedRoomFolderModalFolderId,
  setSharedRoomFolderModalFolderId,
  setShowOpenRoomModal,
} from "redux/spaceNavigationRedux";
import {
  selectOrganizationId,
  selectUserId,
  setCurrentRoomId,
} from "redux/userRedux";
import { evictCurrentRoom } from "utils/dbUtils";
import { logUsageEvent, useTrackEvent } from "utils/metricsUtils";
import { setEditItemId } from "redux/editRoomNavigationRedux";
import { ReactComponent as BackIcon } from "assets/icons/back_arrow.svg";
import folderSharedIcon from "assets/icons/folder_shared.svg";
import { generateSharedRoomCopyName } from "pages/Space/components/ControlBar/RoomMenu/roomNameUtils";
import { RESOURCE_SHARED_TYPES } from "utils/organizationUtils";
import { logUnexpectedError } from "utils/errorUtils";

type SharedRoomFolderModalProps = {
  sendRemoteRoomChanged: () => void;
};

const SharedRoomFolderModal = ({
  sendRemoteRoomChanged,
}: SharedRoomFolderModalProps) => {
  const [selectedSharedRoomId, setSelectedSharedRoomId] = useState<string>();
  const userId = useSelector(selectUserId);
  const organizationId = useSelector(selectOrganizationId);
  const meetingID = useSelector(selectMeetingID);
  const sharedRoomFolderModalFolderId = useSelector(
    selectSharedRoomFolderModalFolderId
  );
  const dispatch = useDispatch();
  const { trackEvent } = useTrackEvent();
  const [setCurrentRoom] = useSetCurrentRoomMutation();
  const [createRoomMutation] = useCreateRoomMutation();
  const [insertAnalyticsBackgroundUpdateMutation] =
    useInsertAnalyticsBackgroundUpdateMutation();
  const { data: sharedRoomFolderModalData } =
    useGetSharedRoomFolderModalDataQuery({
      variables: {
        sharedRoomFolderId: sharedRoomFolderModalFolderId,
        userId,
      },
      skip: !sharedRoomFolderModalFolderId || !userId,
      fetchPolicy: "cache-and-network",
    });

  const closeModal = () => {
    dispatch(setSharedRoomFolderModalFolderId(undefined));
  };

  const openPreviousModal = () => {
    closeModal();
    dispatch(setShowOpenRoomModal(true));
  };

  const copySharedRoom = async (sharedRoomId: string) => {
    const sharedRoom =
      sharedRoomFolderModalData?.sharedRoomFolder?.sharedRooms.find(
        (room) => room.id === sharedRoomId
      );

    if (!userId || !sharedRoom) {
      return;
    }

    const roomItems = sharedRoom.sharedRoomItems.map((sharedRoomItem) => {
      const roomItem: RoomItemInsertInput = {
        source_shared_room_item_id: sharedRoomItem.id,
        icon_file_id: sharedRoomItem.icon_file_id,
        icon_id: sharedRoomItem.icon_id,
        rx: sharedRoomItem.rx,
        ry: sharedRoomItem.ry,
        z: sharedRoomItem.z,
      };

      const resource = sharedRoomItem.resource;
      if (resource.shared_type === RESOURCE_SHARED_TYPES.PROTOTYPE) {
        roomItem["resource"] = {
          data: {
            id: uuid(),
            owner_id: userId,
            source_resource_id: resource.id,
            source_owner_id: resource.source_owner_id,
            organization_id: resource.organization_id,
            shared_type: RESOURCE_SHARED_TYPES.COPY_PENDING,
            name: resource.name,
            url: resource.url,
            file_key: resource.file_key,
            thumbnail_file_key: resource.thumbnail_file_key,
          },
        };
      } else {
        roomItem["resource_id"] = resource.id;
      }

      return roomItem;
    });

    const roomName = generateSharedRoomCopyName(
      sharedRoomFolderModalData?.user?.first_name,
      sharedRoom.name,
      sharedRoomFolderModalData?.existingRoomNames
    );

    const room = {
      source_shared_room_id: sharedRoom.id,
      owner_id: userId,
      name: roomName,
      background_id: sharedRoom.background_id,
      collection_id: sharedRoom.collection_id,
      room_items: { data: roomItems },
    };

    const createRoomMutationResult = await createRoomMutation({
      variables: { room },
      refetchQueries: [
        { query: GetRoomMenuDataDocument, variables: { userId } },
        {
          query: GetOpenRoomModalDataDocument,
          variables: {
            userId,
            hasOrganizationId: !!organizationId,
            organizationId,
          },
        },
      ],
    });

    await insertAnalyticsBackgroundUpdateMutation({
      variables: {
        userId: userId,
        isChosenByClient: false,
        isCreateRoom: true,
        backgroundId: room.background_id,
      },
    }).catch(logUnexpectedError);
    logUsageEvent("ADDED_ROOM", meetingID);

    return createRoomMutationResult.data?.insert_room_one?.id;
  };

  const copyAndOpenSharedRoom = async (sharedRoomId: string) => {
    const roomId = await copySharedRoom(sharedRoomId);

    if (!roomId) {
      return;
    }

    await setCurrentRoom({
      variables: { userId, roomId },
      update: evictCurrentRoom(userId),
      refetchQueries: [
        { query: GetRoomMenuDataDocument, variables: { userId } },
      ],
    });
    logUsageEvent("BACKGROUND_CHANGED", meetingID);
    sendRemoteRoomChanged();
    dispatch(setCurrentRoomId(roomId));
    dispatch(setEditItemId(undefined));
    trackEvent("Room Switch");
    closeModal();
  };

  const copyAndOpenSelectedSharedRoom = async () => {
    if (!selectedSharedRoomId) {
      return;
    }
    copyAndOpenSharedRoom(selectedSharedRoomId);
  };

  return (
    <Modal closeModal={closeModal}>
      <div className={styles.heading}>
        <BackIcon
          className={styles.backArrowIcon}
          onClick={openPreviousModal}
        />
        <img className={styles.folderSharedIcon} src={folderSharedIcon} />
        {sharedRoomFolderModalData?.sharedRoomFolder?.name}
      </div>
      <div className={styles.roomRowsContainer}>
        {sharedRoomFolderModalData?.sharedRoomFolder?.sharedRooms.map(
          (sharedRoom) => (
            <SharedRoomRow
              key={sharedRoom.id}
              sharedRoom={sharedRoom}
              sharedRoomFolderId={sharedRoomFolderModalFolderId}
              onClick={() => {
                if (selectedSharedRoomId === sharedRoom.id) {
                  setSelectedSharedRoomId(undefined);
                  return;
                }
                setSelectedSharedRoomId(sharedRoom.id);
              }}
              onCopy={copySharedRoom}
              isSelected={selectedSharedRoomId === sharedRoom.id}
            />
          )
        )}
      </div>
      <Button
        onClick={copyAndOpenSelectedSharedRoom}
        disabled={!selectedSharedRoomId}
      >
        Copy and Open
      </Button>
    </Modal>
  );
};

export default SharedRoomFolderModal;

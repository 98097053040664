import { useGetRoomQuery } from "generated/graphql";
import { useSelector } from "react-redux";
import { selectMeetingID } from "redux/spaceNavigationRedux";
import { selectUserRole, UserRole } from "redux/userRedux";

export const useGetRoomQuerySharedHook = () => {
  const meetingID = useSelector(selectMeetingID);
  const userRole = useSelector(selectUserRole);
  const isProvider = userRole === UserRole.THERAPIST;

  const {
    data,
    loading: loadingRoomItemsQuery,
    refetch: refetchGetRoomQuery,
  } = useGetRoomQuery({
    variables: {
      meetingID: meetingID || "",
      isProvider: isProvider,
    },
    skip: !meetingID,
    notifyOnNetworkStatusChange: true,
  });

  return {
    data,
    loadingRoomItemsQuery,
    refetchGetRoomQuery,
  };
};

import { getIconConfig, IconConfig } from "../itemsConfig";
import { fabricTypes } from "utils/fabric-impl";
import { defaultShadow, loadImage } from "./drawingUtils";
import { logUnexpectedError } from "utils/errorUtils";
import { fabric } from "utils/fabricUtils";
import { RoomItemFragment } from "generated/graphql";
import { addPosterToRoom, DEFAULT_POSTER_SHADOW } from "./posterUtil";
import { setEditItemMoving } from "redux/editRoomNavigationRedux";
import { Dispatch } from "@reduxjs/toolkit";

export const setShadow = (
  object: fabricTypes.Object,
  editItemIdRef: React.MutableRefObject<string | undefined>,
  newlyAddedRoomitemId?: string
) => {
  const scale = object.scaleX || 1;
  const itemId = object.itemId;
  const isPoster = object.isPoster;
  if (itemId) {
    let shadow = defaultShadow(scale);
    const isNewlyAdded =
      newlyAddedRoomitemId && itemId === newlyAddedRoomitemId;
    if (itemId === editItemIdRef.current || isNewlyAdded) {
      shadow = `rgba(0,0,0,1) ${10 / scale}px ${10 / scale}px ${5 / scale}px`;
    } else if (isPoster) {
      shadow = DEFAULT_POSTER_SHADOW;
    }
    object.set({ shadow });
  }
};

export const doesItemImageMatch = (
  matchingItem: fabricTypes.Image,
  src: any,
  iconFileKey: string | undefined
) => {
  if (matchingItem.isPoster) {
    return matchingItem.iconFileKey === iconFileKey;
  } else {
    return matchingItem.getSrc() === src;
  }
};

export const getPositionInCanvas = (
  top: number,
  left: number,
  width: number,
  height: number,
  canvasWidth: number,
  canvasHeight: number
) => {
  if (!canvasHeight || !canvasWidth) {
    return {
      newTop: top,
      newLeft: left,
    };
  }
  const bottom = top + height;
  const right = left + width;

  const AMOUNT_IN_CANVAS = 200;
  let newTop = top;
  const amountToShowVertical = Math.min(AMOUNT_IN_CANVAS, height);
  if (top < 0 && bottom - AMOUNT_IN_CANVAS < 0) {
    newTop = amountToShowVertical - height;
  } else if (
    bottom > canvasHeight &&
    top + amountToShowVertical > canvasHeight
  ) {
    newTop = canvasHeight - amountToShowVertical;
  }
  let newLeft = left;
  const amountToShowHorizontal = Math.min(AMOUNT_IN_CANVAS, width);
  if (left < 0 && right - amountToShowHorizontal < 0) {
    newLeft = amountToShowHorizontal - width;
  } else if (
    right > canvasWidth &&
    left + amountToShowHorizontal > canvasWidth
  ) {
    newLeft = canvasWidth - amountToShowHorizontal;
  }

  return {
    newTop,
    newLeft,
  };
};

// If the object is partially outside the canvas, moves the object so it is mainly inbounds.
const setPositionInCanvas = (obj: fabricTypes.Object) => {
  const canvasWidth = obj.canvas?.getWidth();
  const canvasHeight = obj.canvas?.getHeight();
  const zoom = obj.canvas?.getZoom();
  const top = obj.top;
  const left = obj.left;
  const height = obj.getScaledHeight();
  const width = obj.getScaledWidth();
  if (
    !canvasWidth ||
    !canvasHeight ||
    !zoom ||
    top === undefined ||
    left === undefined
  ) {
    return;
  }
  const scaledCanvasWidth = canvasWidth / zoom;
  const scaledCanvasHeight = canvasHeight / zoom;
  const { newTop, newLeft } = getPositionInCanvas(
    top,
    left,
    width,
    height,
    scaledCanvasWidth,
    scaledCanvasHeight
  );
  if (newTop !== obj.top) {
    obj.top = newTop;
  }
  if (newLeft !== obj.left) {
    obj.left = newLeft;
  }
};

export const getOrCreateOverlay = (
  canvas: fabricTypes.Canvas,
  size: { width: number; height: number }
) => {
  const objects = canvas.getObjects();
  const existingOverlay = objects.find((object) => object.isOverlay);

  if (existingOverlay) {
    return existingOverlay;
  }

  const overlayRect = new fabric.Rect({
    fill: "#000",
    width: size.width,
    height: size.height,
    selectable: false,
    evented: false,
    opacity: 0.5,
    ry: 0,
    rx: 0,
    isOverlay: true,
  }) as fabricTypes.Rect;
  canvas.add(overlayRect);
  return overlayRect;
};

export const deleteOverlayIfPresent = (canvas: fabricTypes.Canvas) => {
  const objects = canvas.getObjects();
  const existingOverlay = objects.find((object) => object.isOverlay);
  if (existingOverlay) {
    canvas.remove(existingOverlay);
  }
};

export const fixCanvasObjectsSortingOrder = (canvas: fabricTypes.Canvas) => {
  const canvasObjects = canvas.getObjects() || [];
  const filteredObjects = canvasObjects.filter(
    (obj) => obj.z !== undefined && !!obj.itemId
  );
  const sortedObjects = [...filteredObjects].sort((a, b) => a.z! - b.z!);

  for (const obj of sortedObjects) {
    obj.bringToFront();
  }
};

export const updateItemFields = (
  itemImage: fabricTypes.Image,
  item: RoomItemFragment,
  scale: number | undefined,
  backgroundImageSize: React.MutableRefObject<
    { width: number; height: number } | undefined
  >,
  editItemIdRef: React.MutableRefObject<string | undefined>,
  newlyAddedRoomitemId: string | undefined,
  canMove: boolean,
  dispatch: Dispatch
) => {
  const isPoster = item.icon_id === "POSTER";
  itemImage.set({
    top: item.ry * (backgroundImageSize.current?.height || 0),
    left: item.rx * (backgroundImageSize.current?.width || 0),
    scaleX: scale,
    scaleY: scale,
    srcFromAttribute: true,
    hoverCursor: isPoster ? "default" : "pointer",
    filters: isPoster
      ? [new fabric.Image.filters.Blur({ blur: 0.05 })]
      : undefined,
    selectable: canMove,
    lockMovementX: true,
    lockMovementY: true,
  });

  itemImage.set({
    itemId: item.id,
    iconId: item.icon_id,
    // z is our custom property that is ignored by fabricjs
    z: item.z,
    isPoster,
    iconFileKey: item.icon_file?.key,
  });

  setShadow(itemImage, editItemIdRef, newlyAddedRoomitemId);
  itemImage.on("moving", () => {
    dispatch(setEditItemMoving(item.id));
    // Keep at least part of the object in the bounds of the canvas.
    setPositionInCanvas(itemImage);
  });
};

export const addItem = async (
  backgroundImageSize: React.MutableRefObject<
    { width: number; height: number } | undefined
  >,
  canvasRef: React.MutableRefObject<fabricTypes.Canvas | undefined>,
  item: RoomItemFragment,
  editItemIdRef: React.MutableRefObject<string | undefined>,
  newlyAddedRoomitemId: string | undefined,
  canMove: boolean,
  setDoneLoading: () => void,
  setError: () => void,
  iconConfig: IconConfig | undefined,
  dispatch: Dispatch
) => {
  const isPoster = item.icon_id === "POSTER";
  if (isPoster) {
    addPosterToRoom(
      backgroundImageSize,
      canvasRef,
      item,
      editItemIdRef,
      newlyAddedRoomitemId,
      canMove,
      setDoneLoading,
      setError,
      dispatch
    ).catch((e) => {
      logUnexpectedError(e);
      setError();
    });
    return;
  }

  try {
    const oImg = await loadImage(iconConfig?.src);
    updateItemFields(
      oImg,
      item,
      iconConfig?.scale,
      backgroundImageSize,
      editItemIdRef,
      newlyAddedRoomitemId,
      canMove,
      dispatch
    );
    // Only add if it hasn't already been added while waiting for image loading
    // (can happen in certain race conditions)
    const currentObjects = canvasRef.current?.getObjects() || [];
    // @ts-ignore
    const matchingItems: fabricTypes.Image[] = currentObjects.filter(
      (object) => object.itemId === item.id
    );
    if (matchingItems.length === 0) {
      if (canvasRef.current) {
        canvasRef.current.add(oImg);
        fixCanvasObjectsSortingOrder(canvasRef.current);
        canvasRef.current.renderAll();
      }
    }
  } catch (e) {
    logUnexpectedError(e);
    setError();
  } finally {
    setDoneLoading();
  }
};

export const addOrUpdateItem = async (
  item: RoomItemFragment,
  index: number,
  backgroundImageSize: React.MutableRefObject<
    { width: number; height: number } | undefined
  >,
  canvasRef: React.MutableRefObject<fabricTypes.Canvas | undefined>,
  editItemIdRef: React.MutableRefObject<string | undefined>,
  newlyAddedRoomitemId: string | undefined,
  canMove: boolean,
  setError: (value: boolean) => void,
  setLoadingImages: React.Dispatch<React.SetStateAction<boolean[] | undefined>>,
  dispatch: Dispatch
) => {
  const iconConfig = getIconConfig(item.icon_id);

  const setDoneLoadingImage =
    (itemIndex: number, isLoading: boolean) =>
    (previousLoadingImages: boolean[] | undefined) => {
      if (previousLoadingImages) {
        const newLoadingImages = [...previousLoadingImages];
        newLoadingImages[itemIndex] = isLoading;
        return newLoadingImages;
      }
      return undefined;
    };
  const setDoneLoadingThisImage = () =>
    setLoadingImages(setDoneLoadingImage(index, false));

  const currentObjects = canvasRef.current?.getObjects() || [];
  // @ts-ignore
  const matchingItems: fabricTypes.Image[] = currentObjects.filter(
    (object) => object.itemId === item.id
  );
  let foundMatchingImageItem = false;
  for (const matchingItem of matchingItems) {
    const itemImageMatches = doesItemImageMatch(
      matchingItem,
      iconConfig?.src,
      item.icon_file?.key
    );
    if (itemImageMatches) {
      if (foundMatchingImageItem) {
        // Delete existing image if there is more than one that matches.
        // Generally there should only be one of each icon total, but there are race
        // conditions where there might incorrectly end up being more than one poster added, and this
        // recovers (sloppily) from that case.
        canvasRef.current?.remove(matchingItem);
        continue;
      }
      foundMatchingImageItem = true;

      updateItemFields(
        matchingItem,
        item,
        iconConfig?.scale,
        backgroundImageSize,
        editItemIdRef,
        newlyAddedRoomitemId,
        canMove,
        dispatch
      );
      matchingItem.setCoords(); // Otherwise sometimes the items are not selectable, see http://fabricjs.com/fabric-gotchas
      if (canvasRef.current) {
        fixCanvasObjectsSortingOrder(canvasRef.current);
      }
    } else {
      canvasRef.current?.remove(matchingItem);
    }
  }
  if (!foundMatchingImageItem) {
    setLoadingImages(setDoneLoadingImage(index, true));
    addItem(
      backgroundImageSize,
      canvasRef,
      item,
      editItemIdRef,
      newlyAddedRoomitemId,
      canMove,
      setDoneLoadingThisImage,
      () => setError(true),
      iconConfig,
      dispatch
    ).catch((e) => {
      logUnexpectedError(e);
      setError(true);
    });
  }

  canvasRef.current?.renderAll();
};

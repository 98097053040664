import { RoomItemFragment } from "generated/graphql";
import { ResourceType } from "redux/editRoomNavigationRedux";
import {
  scrubResourceData,
  useTrackEvent,
  useTrackEventWithShowHide,
} from "utils/metricsUtils";

export type RoomEditingActivityBankActionSourceType = "button" | "row";

export const useActivityBankEditingAnalytics = () => {
  const { trackEvent } = useTrackEvent();

  const trackAddActivity = (
    resource: ResourceType,
    source: RoomEditingActivityBankActionSourceType
  ) => {
    const scrubbedResource = scrubResourceData(resource);
    trackEvent("Room Editing - activity added", {
      ["Activity Resource ID"]: scrubbedResource.id,
      ["Activity Resource Name"]: scrubbedResource.name,
      ["source"]: source,
    });
  };

  const trackSwitchActivity = (
    newResource: ResourceType,
    previousResource: RoomItemFragment["resource"] | undefined,
    source: RoomEditingActivityBankActionSourceType
  ) => {
    const scrubbedNewResource = scrubResourceData(newResource);
    const scrubbedPreviousResource = previousResource
      ? scrubResourceData(previousResource)
      : { id: "<UNDEFINED>", name: "<UNDEFINED>" };
    trackEvent("Room Editing - activity changed", {
      ["New Activity Resource ID"]: scrubbedNewResource.id,
      ["New Activity Resource Name"]: scrubbedNewResource.name,
      ["Previous Activity Resource ID"]: scrubbedPreviousResource.id,
      ["Previous Activity Resource Name"]: scrubbedPreviousResource.name,
      ["source"]: source,
    });
  };

  return {
    trackAddActivity,
    trackSwitchActivity,
  };
};

export const useRoomEditingHoverButtonsAnalytics = () => {
  const { trackEvent } = useTrackEvent();

  const trackEditClick = (
    resource: RoomItemFragment["resource"],
    sameRoomItem: boolean
  ) => {
    const scrubbedResource = scrubResourceData(resource);
    trackEvent("Room Editing - edit hover button clicked", {
      ["Resource ID"]: scrubbedResource.id,
      ["Resource Name"]: scrubbedResource.name,
      ["Same Room Item"]: sameRoomItem,
    });
  };

  const trackEditPosterClick = (sameRoomItem: boolean) => {
    trackEvent("Room Editing - edit poster hover button clicked", {
      ["Same Room Item"]: sameRoomItem,
    });
  };

  const trackChangeIconClick = (resource: RoomItemFragment["resource"]) => {
    const scrubbedResource = scrubResourceData(resource);

    trackEvent("Room Editing - change icon hover button clicked", {
      ["Resource ID"]: scrubbedResource.id,
      ["Resource Name"]: scrubbedResource.name,
    });
  };

  const trackDeleteClick = (resource: RoomItemFragment["resource"]) => {
    const scrubbedResource = scrubResourceData(resource);
    trackEvent("Room Editing - delete hover button clicked", {
      ["Resource ID"]: scrubbedResource.id,
      ["Resource Name"]: scrubbedResource.name,
    });
  };

  return {
    trackEditClick,
    trackEditPosterClick,
    trackChangeIconClick,
    trackDeleteClick,
  };
};

export const useRoomEditingChangeIconModalAnalytics = () => {
  const { trackEvent } = useTrackEventWithShowHide(
    "Change room item icon modal"
  );

  const trackChangeRoomItemIcon = (
    newIconId: string,
    previousIconId: string,
    resource: RoomItemFragment["resource"]
  ) => {
    const scrubbedResource = scrubResourceData(resource);
    trackEvent("Room Editing - room item icon changed", {
      ["New Icon ID"]: newIconId,
      ["Previous Icon ID"]: previousIconId,
      ["Resource ID"]: scrubbedResource.id,
      ["Resource Name"]: scrubbedResource.name,
    });
  };

  return {
    trackChangeRoomItemIcon,
  };
};

export const useRoomEditingDeleteModalAnalytics = () => {
  const { trackEvent } = useTrackEventWithShowHide(
    "Delete item confirmation modal"
  );

  const trackDeleteRoomItem = (resource: RoomItemFragment["resource"]) => {
    const scrubbedResource = scrubResourceData(resource);
    trackEvent("Room Editing - room item deleted", {
      ["Resource ID"]: scrubbedResource.id,
      ["Resource Name"]: scrubbedResource.name,
    });
  };

  return {
    trackDeleteRoomItem,
  };
};

export type RoomEditingOpenActivityBankSourceType =
  | "Control Bar Activities Button"
  | "Edit Item Hover Button";

export const useRoomEditingOpenActivityBankAnalytics = () => {
  const { trackEvent } = useTrackEvent();

  const trackOpenActivityBank = (
    source: RoomEditingOpenActivityBankSourceType
  ) => {
    trackEvent("Room Editing - activity bank opened", {
      ["source"]: source,
    });
  };

  return {
    trackOpenActivityBank,
  };
};

export type RoomEditingCloseActivityBankSourceType =
  | "Close Button"
  | "Backdrop"
  | "Control Bar Activities Button"
  | "Control Bar Client Button"
  | "Launch Activity";

export const useRoomEditingCloseActivityBankAnalytics = () => {
  const { trackEvent } = useTrackEvent();

  const trackCloseActivityBank = (
    source: RoomEditingCloseActivityBankSourceType
  ) => {
    trackEvent("Room Editing - activity bank closed", {
      ["source"]: source,
    });
  };

  return {
    trackCloseActivityBank,
  };
};

export type RoomEditingUnselectItemSourceType = "Backdrop" | "Room Item Click";

export const useRoomEditingUnselectItemAnalytics = () => {
  const { trackEvent } = useTrackEvent();

  const trackUnselectItem = (source: RoomEditingUnselectItemSourceType) => {
    trackEvent("Room Editing - item unselected", {
      ["source"]: source,
    });
  };

  return {
    trackUnselectItem,
  };
};

export const useRoomEditingChangePosterAnalytics = () => {
  const { trackEvent } = useTrackEvent();

  const trackChangePoster = () => {
    trackEvent("Room Editing - poster changed", {});
  };

  return { trackChangePoster };
};

import { backendRequest } from "utils/backendRequest";
import { logUnexpectedError } from "./errorUtils";
import { generateThumbnailForFile } from "./thumbnailUtils";
import { THUMBNAIL_ERROR_KEY } from "pages/Space/subpages/SpaceRoom/utils/drawingUtils";

// Cache file urls that we've already gotten, to take advantage of browser caching
const fileUrlCache = new Map<string, string>(); // map from key to url

export const getFileUrl = async (
  key: string,
  type: "activity" | "snapshot" = "activity",
  setDownloadHeaders: boolean = false
): Promise<string> => {
  if (!fileUrlCache.has(key + (setDownloadHeaders ? "-download" : ""))) {
    // Get presigned download link
    const result = await backendRequest({
      path: "/file-download",
      searchParams: {
        key,
        type,
        setDownloadHeaders: setDownloadHeaders + "" || "false",
      },
    });
    const url = (await result.json()).downloadURL;
    fileUrlCache.set(key + (setDownloadHeaders ? "-download" : ""), url);
  }
  return fileUrlCache.get(key + (setDownloadHeaders ? "-download" : "")) || "";
};

export const sanitizeFileKeys = (keys: (string | null | undefined)[]) => {
  const thumbnailKeysSet = new Set(keys);

  const keysToGet = Array.from(thumbnailKeysSet.values()).filter(
    (key): key is string =>
      typeof key === "string" && !!key && key !== THUMBNAIL_ERROR_KEY
  );

  return keysToGet;
};

export const getFileUrls = async (
  keys: string[],
  type: "activity" | "snapshot" = "activity"
) => {
  const uncachedKeys = keys.filter((key) => !fileUrlCache.has(key));
  if (uncachedKeys.length > 0) {
    // Get presigned download link
    const result = await backendRequest({
      path: "/file-downloads",
      searchParams: {
        keys: JSON.stringify(uncachedKeys),
        type,
      },
    });
    const resultJson = await result.json();
    resultJson.forEach((obj: any) => {
      fileUrlCache.set(obj.key, obj.downloadURL);
    });
  }
  const keyUrlMap = keys.reduce((map: any, key: string) => {
    map[key] = fileUrlCache.get(key);
    return map;
  }, {});
  return keyUrlMap;
};

const getFileUploadUrl = async (
  filename: string,
  contentType: string,
  type: "activity" | "snapshot" = "activity"
) => {
  const result = await backendRequest({
    path: "/file-upload",
    searchParams: {
      filename,
      contentType,
      type,
    },
  });

  if (!result.ok) {
    throw new Error("Problem getting file upload url");
  }
  return await result.json();
};

export const deleteFile = async (key: string) => {
  await backendRequest({
    path: "/file-delete",
    searchParams: {
      key,
    },
  });
};

const getFileExtension = (filename: string) => {
  return filename.substring(filename.lastIndexOf(".") + 1).toLowerCase();
};

const IMAGE_EXTENSIONS = ["jpg", "jpeg", "png"]; // TODO: support svg

export const isImageFile = (filename: string) => {
  return IMAGE_EXTENSIONS.includes(getFileExtension(filename));
};

export const isPdfFile = (filename: string) => {
  return getFileExtension(filename) === "pdf";
};

export const isSupportedFile = (filename: string) => {
  return isImageFile(filename) || isPdfFile(filename);
};

export const uploadFile = async (
  file: File,
  type: "activity" | "snapshot" = "activity"
) => {
  try {
    if (file) {
      const { uploadURL, key } = await getFileUploadUrl(
        file.name,
        file.type,
        type
      );
      if (uploadURL) {
        await fetch(uploadURL, { method: "PUT", body: file });
      }
      return { key };
    }
  } catch (err) {
    logUnexpectedError(err);
    return false;
  }
};

export const uploadFileWithThumbnail = async (
  file: File,
  type: "activity" | "snapshot" = "activity",
  crop: boolean = true
) => {
  try {
    if (file) {
      const thumbnail = await generateThumbnailForFile(file, crop);
      const { uploadURL, key, thumbnailUploadURL, thumbnailKey } =
        await getFileUploadUrl(file.name, file.type, type);
      const promisesToWait = [];
      if (uploadURL) {
        promisesToWait.push(fetch(uploadURL, { method: "PUT", body: file }));
      }
      if (thumbnailUploadURL) {
        promisesToWait.push(
          await fetch(thumbnailUploadURL, { method: "PUT", body: thumbnail })
        );
      }
      await Promise.all(promisesToWait);
      return { key, thumbnailKey };
    }
  } catch (err) {
    logUnexpectedError(err);
    return false;
  }
};

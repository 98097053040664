import React, { useState } from "react";

import styles from "./SharePubliclyModal.module.css";
import Button from "components/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  selectSharePubliclyModalData,
  setSharePubliclyModalData,
} from "redux/editRoomNavigationRedux";
import Modal from "components/Modal/Modal";
import { logUnexpectedError } from "utils/errorUtils";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import { submitPublicResource } from "utils/resourceUtils";
import ResourceEntry from "pages/Space/subpages/SpaceRoom/components/ResourceEntry/ResourceEntry";

const SharePubliclyModal = () => {
  const dispatch = useDispatch();
  const data = useSelector(selectSharePubliclyModalData);
  const resource = data?.resource;
  const thumbnailSrc = data?.thumbnailSrc;
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const closeModal = () => {
    dispatch(setSharePubliclyModalData(null));
  };

  const submit = async () => {
    if (!resource) {
      return;
    }
    setLoading(true);
    const success = await submitPublicResource(resource.id);
    if (success) {
      setHasSubmitted(true);
    } else {
      alert(
        "There was an error sharing your activity. Please try again later."
      );
      logUnexpectedError("Error sharing new activity suggestion");
    }
    setLoading(false);
  };

  if (hasSubmitted) {
    return (
      <ConfirmationModal closeModal={closeModal}>
        Thank you for sharing with our clinical team!
        <br />
        We will review your resource and possibly incorporate it soon.
      </ConfirmationModal>
    );
  }

  return (
    <Modal closeModal={closeModal}>
      <div className={styles.container}>
        <div className={styles.heading}>Share Activity</div>
        {resource ? (
          <ResourceEntry
            resource={resource}
            thumbnailSrc={thumbnailSrc}
            displayOnly
          />
        ) : null}
        <div className={styles.text}>
          Would you like to share this activity with Teleo clinical advisors for
          possible inclusion in the Activity Bank?
        </div>
        <div className={styles.buttonRow}>
          <Button className={styles.cancelButton} onClick={closeModal}>
            Cancel
          </Button>
          <Button onClick={submit} disabled={loading}>
            {loading ? "..." : "Share"}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default SharePubliclyModal;

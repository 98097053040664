import { useRef, useEffect } from "react";
import { Socket } from "socket.io-client";
import { getAddBreadcrumb, logUnexpectedError } from "utils/errorUtils";
import { useTrackEvent } from "utils/metricsUtils";
import { flattenObject } from "utils/objectUtils";
import { Peers } from "../usePeerWebRTCConnection";

export type HealthMeasurement = {
  isConnectedToGateway: true;
  isConnectedToRemote: true;
  hasPeers: boolean;
  peersHealthStatus: {
    peerID: string;
    isConnected: boolean;
    isDataChannelOpen: boolean;
    hasActiveMediaStream: boolean;
    hasOneVideoTrack: boolean;
    hasOneAudioTrack: boolean;
    isVideoUnmuted: boolean;
    isAudioUnmuted: boolean;
  }[];
  remoteHealthStatus?: {
    isConnected: boolean;
    isDataChannelOpen: boolean;
    hasActiveMediaStream: boolean;
    videoTracksLength?: number;
    audioTracksLength?: number;
    hasOneVideoTrack: boolean;
    hasOneAudioTrack: boolean;
    isVideoUnmuted: boolean;
    isAudioUnmuted: boolean;
  };
  timestamp: number;
  isBrowserOnline: boolean;
};

export type ConnectionHealthStatusResult = {
  healthyCount: number;
  unhealthyCount: number;
  mixedCount: number;
  total: number;
};

export const addBreadcrumb = getAddBreadcrumb("connectionHealth");

// 10 seconds
const HEALTH_CHECK_INTERVAL = 10 * 1000;

export const useCollectTeleoConnectionStats = (
  peersRef: React.MutableRefObject<Peers>,
  remoteWebRTCConnectionRef: React.MutableRefObject<
    RTCPeerConnection | undefined
  >,
  remoteDataChannelRef: React.MutableRefObject<RTCDataChannel | undefined>,
  remoteMediaStreamRef: React.MutableRefObject<MediaStream | undefined>,
  remoteSocketRef: React.MutableRefObject<Socket | undefined>,
  gatewaySocketRef: React.MutableRefObject<Socket | undefined>
) => {
  const { trackEvent } = useTrackEvent();
  const measurementsOverTimeRef = useRef<HealthMeasurement[]>([]);
  useEffect(() => {
    const intervalID = setInterval(async () => {
      try {
        const isConnectedToGateway =
          !!gatewaySocketRef.current?.connected || true;
        const isConnectedToRemote =
          !!remoteSocketRef.current?.connected || true;
        const hasPeers = Object.keys(peersRef.current).length > 0;

        const peersHealthStatus = await Promise.all(
          Object.entries(peersRef.current).map(async ([peerID, peer]) => {
            const isConnected =
              peer.webRTCConnection?.connectionState === "connected";
            const isDataChannelOpen = peer.dataChannel?.readyState === "open";
            const hasActiveMediaStream = peer.mediaStream?.active;
            const videoTracksLength = peer.mediaStream?.getVideoTracks().length;
            const audioTracksLength = peer.mediaStream?.getAudioTracks().length;
            const hasOneVideoTrack = videoTracksLength === 1;
            const hasOneAudioTrack = audioTracksLength === 1;
            const isVideoUnmuted =
              !peer.mediaStream?.getVideoTracks()[0]?.muted;
            const isAudioUnmuted =
              !peer.mediaStream?.getAudioTracks()[0]?.muted;

            // Every boolean will be used to determine the health of the connection
            // Non-boolean props will be ignored.
            const peerMeasurement = {
              peerID,
              isConnected,
              isDataChannelOpen,
              hasActiveMediaStream,
              videoTracksLength,
              audioTracksLength,
              hasOneVideoTrack,
              hasOneAudioTrack,
              isVideoUnmuted,
              isAudioUnmuted,
            };

            // in firefox, getStats might fail after the connection is closed
            if (
              peer.webRTCConnection &&
              peer.webRTCConnection.connectionState !== "closed"
            ) {
              const stats = await peer.webRTCConnection?.getStats();
              const statsArray = Array.from(stats?.values() ?? []);
              const outboundVideoStats = statsArray.find(
                (stat) =>
                  stat?.type === "outbound-rtp" && stat?.mediaType === "video"
              );
              const outboundAudioStats = statsArray.find(
                (stat) =>
                  stat?.type === "outbound-rtp" && stat?.mediaType === "audio"
              );
              const inboundVideoStats = statsArray.find(
                (stat) =>
                  stat?.type === "inbound-rtp" && stat?.mediaType === "video"
              );
              const inboundAudioStats = statsArray.find(
                (stat) =>
                  stat?.type === "inbound-rtp" && stat?.mediaType === "audio"
              );
              const dataChannelStats = statsArray.find(
                (stat) => stat?.type === "data-channel"
              );

              // In some cases, there are more than one candidate pair
              // So we select the one with the most recent lastPacketReceivedTimestamp
              const candidatePairStats = statsArray
                .filter(
                  (stat) =>
                    stat?.type === "candidate-pair" &&
                    stat?.nominated &&
                    stat?.state === "succeeded"
                )
                .sort(
                  (a, b) =>
                    b?.lastPacketReceivedTimestamp -
                    a?.lastPacketReceivedTimestamp
                )[0];

              const mixpanelStatsProps = {
                ...flattenObject(outboundVideoStats, "outboundVideoStats"),
                ...flattenObject(outboundAudioStats, "outboundAudioStats"),
                ...flattenObject(inboundVideoStats, "inboundVideoStats"),
                ...flattenObject(inboundAudioStats, "inboundAudioStats"),
                ...flattenObject(candidatePairStats, "candidatePairStats"),
                ...flattenObject(dataChannelStats, "dataChannelStats"),
              };

              addBreadcrumb("debug", "Teleo Peer Connection Stats", {
                outboundVideoStats,
                outboundAudioStats,
                inboundVideoStats,
                inboundAudioStats,
                candidatePairStats,
                dataChannelStats,
              });

              const mixpanelProps: {
                [key: string]: string | number | boolean;
              } = {
                ...peerMeasurement,
                ...mixpanelStatsProps,
              };

              trackEvent("Teleo Peer Connection Stats", mixpanelProps);
            }
            return peerMeasurement;
          })
        );

        let remoteHealthStatus: HealthMeasurement["remoteHealthStatus"];

        // in firefox, getStats might fail after the connection is closed
        if (
          remoteWebRTCConnectionRef.current &&
          remoteWebRTCConnectionRef.current.connectionState !== "closed"
        ) {
          const remoteWebRTCStats =
            await remoteWebRTCConnectionRef.current.getStats();
          const remoteWebRTCStatsArray = Array.from(
            remoteWebRTCStats?.values() ?? []
          );
          const remoteInboundVideoStats = remoteWebRTCStatsArray.find(
            (stats) =>
              stats?.type === "inbound-rtp" && stats?.mediaType === "video"
          );
          const remoteInboundAudioStats = remoteWebRTCStatsArray.find(
            (stats) =>
              stats?.type === "inbound-rtp" && stats?.mediaType === "audio"
          );
          // In some cases, there are more than one candidate pair
          // So we select the one with the most recent lastPacketReceivedTimestamp
          const remoteCandidatePairStats = remoteWebRTCStatsArray
            .filter(
              (stat) =>
                stat?.type === "candidate-pair" &&
                stat?.nominated &&
                stat?.state === "succeeded"
            )
            .sort(
              (a, b) =>
                b?.lastPacketReceivedTimestamp - a?.lastPacketReceivedTimestamp
            )[0];
          const remoteDataChannelStats = remoteWebRTCStatsArray.find(
            (stat) => stat?.type === "data-channel"
          );

          // Every boolean will be used to determine the health of the connection
          // Non-boolean props will be ignored.
          remoteHealthStatus = {
            isConnected:
              remoteWebRTCConnectionRef.current?.connectionState ===
              "connected",
            isDataChannelOpen:
              remoteDataChannelRef.current?.readyState === "open",
            hasActiveMediaStream: !!remoteMediaStreamRef.current?.active,
            videoTracksLength:
              remoteMediaStreamRef.current?.getVideoTracks().length,
            audioTracksLength:
              remoteMediaStreamRef.current?.getAudioTracks().length,
            hasOneVideoTrack:
              remoteMediaStreamRef.current?.getVideoTracks().length === 1,
            hasOneAudioTrack:
              remoteMediaStreamRef.current?.getAudioTracks().length === 1,
            isVideoUnmuted:
              !remoteMediaStreamRef.current?.getVideoTracks()[0]?.muted,
            isAudioUnmuted:
              !remoteMediaStreamRef.current?.getAudioTracks()[0]?.muted,
          };
          addBreadcrumb("debug", "Teleo Remote Connection Stats", {
            remoteHealthStatus,
            remoteInboundVideoStats,
            remoteInboundAudioStats,
            remoteCandidatePairStats,
            remoteDataChannelStats,
          });

          trackEvent("Teleo Remote Connection Stats", {
            ...remoteHealthStatus,
            ...flattenObject(remoteInboundVideoStats, "inboundVideoStats"),
            ...flattenObject(remoteInboundAudioStats, "inboundAudioStats"),
            ...flattenObject(remoteCandidatePairStats, "candidatePairStats"),
            ...flattenObject(remoteDataChannelStats, "dataChannelStats"),
          });
        }

        const healthStatus = {
          isConnectedToGateway,
          isConnectedToRemote,
          hasPeers,
          peersHealthStatus,
          remoteHealthStatus,
          timestamp: Date.now(),
          isBrowserOnline: navigator.onLine,
        };

        measurementsOverTimeRef.current.push(healthStatus);
      } catch (err) {
        logUnexpectedError(err);
      }
    }, HEALTH_CHECK_INTERVAL);
    return () => {
      clearInterval(intervalID);
    };
  }, []);

  const getConnectionHealthStatusResults = () => {
    const results = [];
    try {
      for (let i = 0; i < measurementsOverTimeRef.current.length; i++) {
        const measurement = measurementsOverTimeRef.current[i];

        // if there is any boolean property with false, the connection is unhealthy
        const peerResults = measurement.peersHealthStatus.map(
          (peerHealthStatus: any) => {
            if (Object.values(peerHealthStatus).includes(false)) {
              return "unhealthy";
            }
            return "healthy";
          }
        );

        // if there is any boolean property with false, the connection is unhealthy
        const remoteResults = measurement.remoteHealthStatus
          ? Object.values(measurement.remoteHealthStatus).includes(false)
            ? "unhealthy"
            : "healthy"
          : "unhealthy";

        if (
          !measurement.isConnectedToGateway &&
          !measurement.isConnectedToRemote &&
          remoteResults === "unhealthy" &&
          (!measurement.hasPeers ||
            peerResults.every((result: string) => result === "unhealthy"))
        ) {
          results.push("unhealthy");
          continue;
        }

        if (
          measurement.isConnectedToGateway &&
          measurement.isConnectedToRemote &&
          remoteResults === "healthy" &&
          (!measurement.hasPeers ||
            peerResults.every((result: string) => result === "healthy"))
        ) {
          results.push("healthy");
          continue;
        }

        results.push("mixed");
      }
    } catch (err) {
      logUnexpectedError(err);
    }

    const sessionStats: ConnectionHealthStatusResult = {
      healthyCount: results.filter((result) => result === "healthy").length,
      unhealthyCount: results.filter((result) => result === "unhealthy").length,
      mixedCount: results.filter((result) => result === "mixed").length,
      total: results.length,
    };

    return sessionStats;
  };

  const clearMeasurements = () => {
    measurementsOverTimeRef.current = [];
  };

  return {
    getConnectionHealthStatusResults,
    clearMeasurements,
  };
};

import {
  selectHasLaunchedActivityFromFullScreenVideo,
  setBrowserSandboxInitialize,
  setBrowserSandboxUrl,
  setCurrentPage,
  setCurrentResourceId,
  setCurrentRoomItemId,
  setHasLaunchedActivityFromFullScreenVideo,
  setIsFullScreenVideo,
  setShowRoomCustomizationActivityModal,
  setWhiteboardBackground,
  SpacePage,
} from "redux/spaceNavigationRedux";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  EventFunctionArgs,
  NavigateEventData,
} from "pages/Space/eventMessagesTypes";
import { getAddBreadcrumb } from "utils/errorUtils";
import { Peers } from "./connection/usePeerWebRTCConnection";
import { useBoxRef } from "hooks/useBoxRef";
import { useTrackEvent } from "utils/metricsUtils";

const addBreadcrumb = getAddBreadcrumb("navigation");

// Listen for incoming "navigate" messages on the peer data channel, and update state correspondingly
export const useNavigateListener = (peers: Peers) => {
  const dispatch = useDispatch();
  const { trackEvent } = useTrackEvent();
  const hasLaunchedActivityFromFullScreenVideo = useSelector(
    selectHasLaunchedActivityFromFullScreenVideo
  );
  const hasLaunchedActivityFromFullScreenVideoRef = useBoxRef(
    hasLaunchedActivityFromFullScreenVideo
  );

  const navigationReceiveMessageCallback = (event: MessageEvent) => {
    const data = JSON.parse(event.data);
    const eventType = data.event as EventFunctionArgs[0];
    if (eventType === "navigate") {
      const navigationState = data.data as NavigateEventData;
      dispatch(setWhiteboardBackground(navigationState.whiteboardBackground));
      dispatch(setBrowserSandboxUrl(navigationState.browserSandboxUrl));
      dispatch(setBrowserSandboxInitialize(false));
      dispatch(setCurrentPage(navigationState.currentPage));

      if (
        navigationState.currentPage === SpacePage.ROOM &&
        hasLaunchedActivityFromFullScreenVideoRef.current
      ) {
        dispatch(setIsFullScreenVideo(true));
        trackEvent("Video chat - talk mode opened", {
          ["source"]: "Peer navigated back to room",
        });
      } else {
        dispatch(setIsFullScreenVideo(false));
        trackEvent("Video chat - talk mode closed", {
          ["source"]: "Peer navigated to activity",
        });
      }

      dispatch(setCurrentResourceId(navigationState.resourceId));
      dispatch(setCurrentRoomItemId(navigationState.roomItemId));
      dispatch(
        setShowRoomCustomizationActivityModal(
          navigationState.showRoomCustomizationActivityModal
        )
      );
      dispatch(
        setHasLaunchedActivityFromFullScreenVideo(
          navigationState.isLaunchingFromFullScreenVideo
        )
      );

      addBreadcrumb("info", "peer navigated", {
        page: navigationState.currentPage,
      });
    }
  };

  useEffect(() => {
    const peerDataChannels = Object.values(peers).map(
      (peer) => peer.dataChannel
    );
    for (const peerDataChannel of peerDataChannels) {
      if (peerDataChannel) {
        peerDataChannel.addEventListener(
          "message",
          navigationReceiveMessageCallback
        );
      }
    }

    return () => {
      for (const peerDataChannel of peerDataChannels) {
        if (peerDataChannel) {
          peerDataChannel.removeEventListener(
            "message",
            navigationReceiveMessageCallback
          );
        }
      }
    };
  }, [peers]);
};

import React from "react";
import styles from "./ZoomScrollLockOverlayControls.module.css";

import { ReactComponent as ZoomOutIcon } from "assets/icons/zoom_out.svg";
import { ReactComponent as ZoomInIcon } from "assets/icons/zoom_in.svg";
import { ReactComponent as ViewportLinkedIcon } from "assets/icons/viewport_linked.svg";
import { ReactComponent as ViewportUnlinkedIcon } from "assets/icons/viewport_unlinked.svg";
import { selectUserRole, UserRole } from "redux/userRedux";
import { selectIsViewportLocked } from "redux/settingsRedux";
import { useSelector } from "react-redux";
import Tooltip from "components/Tooltip/Tooltip";
import { zoomLevelType } from "./whiteboardTypes";
import {
  useTrackViewportLockButton,
  useTrackZoomButton,
} from "./useWhiteboardAnalytics";

type SingleZoomButtonProps = {
  ZoomIcon: React.FC<React.SVGProps<SVGSVGElement>>;
  zoomLevel: zoomLevelType;
  onClick: () => void;
};

const SingleZoomButton = ({
  ZoomIcon,
  zoomLevel,
  onClick,
}: SingleZoomButtonProps) => {
  useTrackZoomButton(zoomLevel);

  return (
    <div className={styles.container}>
      <div className={styles.singleSection} onClick={onClick}>
        <ZoomIcon className={styles.zoomIcon} />
      </div>
    </div>
  );
};

type ZoomScrollLockButtonsProps = {
  ZoomIcon: React.FC<React.SVGProps<SVGSVGElement>>;
  zoomLevel: zoomLevelType;
  isViewportLocked: boolean;
  onZoomClick: () => void;
  onToggleViewportLock: () => void;
};

const ZoomScrollLockButtons = ({
  ZoomIcon,
  zoomLevel,
  isViewportLocked,
  onZoomClick,
  onToggleViewportLock,
}: ZoomScrollLockButtonsProps) => {
  useTrackZoomButton(zoomLevel);
  useTrackViewportLockButton(isViewportLocked);

  const ViewportLockedIcon = isViewportLocked
    ? ViewportLinkedIcon
    : ViewportUnlinkedIcon;

  const viewportLockedText = isViewportLocked ? "ON" : "OFF";

  const tooltipText = `${
    isViewportLocked ? "Turn off" : "Turn on"
  } linked scrolling and zooming with your client`;

  return (
    <div className={styles.container}>
      <button
        id="zoomButton"
        className={styles.rightSection}
        onClick={onZoomClick}
      >
        <ZoomIcon className={styles.zoomIcon} />
      </button>
      <div className={styles.divider} />
      <Tooltip
        containerClassName={styles.tooltipContainer}
        elementWrapperClassName={styles.tooltipElementWrapper}
        text={tooltipText}
        placement="bottom"
      >
        <button
          id="viewportLockButton"
          className={styles.leftSection}
          onClick={onToggleViewportLock}
        >
          <ViewportLockedIcon className={styles.icon} />
          <div className={styles.text}>{viewportLockedText}</div>
        </button>
      </Tooltip>
    </div>
  );
};

type ZoomScrollLockOverlayControlsProps = {
  is2xZoomEnabled: boolean;
  is3xZoomEnabled: boolean;
  zoomLevel: zoomLevelType;
  setZoomLevel: (value: zoomLevelType) => void;
  onToggleViewportLock: () => void;
};

const ZoomScrollLockOverlayControls = ({
  is2xZoomEnabled,
  is3xZoomEnabled,
  zoomLevel,
  setZoomLevel,
  onToggleViewportLock,
}: ZoomScrollLockOverlayControlsProps) => {
  const userRole = useSelector(selectUserRole);
  const isViewportLocked = useSelector(selectIsViewportLocked);

  const onZoomClick = () => {
    let nextZoomLevel: zoomLevelType;
    switch (zoomLevel) {
      case "1x":
        nextZoomLevel = is2xZoomEnabled ? "2x" : "3x";
        setZoomLevel(nextZoomLevel);
        break;
      case "2x":
        nextZoomLevel = is3xZoomEnabled ? "3x" : "1x";
        setZoomLevel(nextZoomLevel);
        break;
      case "3x":
        setZoomLevel("1x");
        break;
    }
  };

  const isZoomEnabled =
    is2xZoomEnabled || is3xZoomEnabled || zoomLevel !== "1x";

  if (!isZoomEnabled) {
    return null;
  }

  const ZoomIcon =
    zoomLevel === "3x" || !is3xZoomEnabled ? ZoomOutIcon : ZoomInIcon;

  if (userRole !== UserRole.THERAPIST) {
    return (
      <SingleZoomButton
        onClick={onZoomClick}
        zoomLevel={zoomLevel}
        ZoomIcon={ZoomIcon}
      />
    );
  }

  return (
    <ZoomScrollLockButtons
      ZoomIcon={ZoomIcon}
      zoomLevel={zoomLevel}
      isViewportLocked={isViewportLocked}
      onZoomClick={onZoomClick}
      onToggleViewportLock={onToggleViewportLock}
    />
  );
};

export default ZoomScrollLockOverlayControls;

import { fabric } from "utils/fabricUtils";
import { fabricTypes } from "utils/fabric-impl";
import { getFileUrl, isImageFile, isPdfFile } from "utils/fileUtils";
import { loadPdfPageCanvas } from "utils/pdfUtils";
import { logUnexpectedError } from "utils/errorUtils";
import { copyFabricImage, loadImage } from "./drawingUtils";
import { clearFabricCanvas } from "utils/canvasUtils";
import { RoomItemFragment } from "generated/graphql";
import { updateItemFields } from "./itemDrawingUtils";
import { ICON_ID } from "../itemsConfig";
import { Dispatch } from "@reduxjs/toolkit";
import { store } from "redux/reduxStore";
import { getOrganizationConfig } from "utils/organizationUtils";
import { selectOrganizationId } from "redux/userRedux";

import welcomePosterImage from "assets/itemIcons/poster/welcomePoster.png";
import posterMaskImage from "assets/itemIcons/poster/posterMask.png";
import posterOutlineImage from "assets/itemIcons/poster/posterOutline.png";

export const DEFAULT_POSTER_SHADOW = "rgba(0,0,0,0.1) 4px 3px 0px";

export const isPoster = (item: RoomItemFragment) => {
  return item.icon_id === ICON_ID.POSTER;
};

const getRawPosterImage = async (iconFileKey: string | undefined) => {
  if (iconFileKey) {
    const posterImageUrl = await getFileUrl(iconFileKey);
    if (isImageFile(iconFileKey)) {
      return await loadImage(posterImageUrl);
    }
    if (isPdfFile(iconFileKey)) {
      const pdfPageCanvas = await loadPdfPageCanvas(posterImageUrl);
      return new fabric.Image(pdfPageCanvas);
    }
    return null;
  }

  const state = store.getState();
  const organizationId = selectOrganizationId(state);
  const organizationConfig = getOrganizationConfig(organizationId);
  if (organizationConfig?.logo) {
    return await loadImage(organizationConfig.logo);
  }
  return await loadImage(welcomePosterImage);
};

const rawPosterImageCache = new Map<string, fabricTypes.Image | null>();

// Avoid re-downloading the image each time the user goes back to the room
const getRawPosterImageCached = async (iconFileKey: string | undefined) => {
  const cacheKey = iconFileKey ?? "undefined";
  if (rawPosterImageCache.has(cacheKey) && rawPosterImageCache.get(cacheKey)) {
    const cachedPoster = rawPosterImageCache.get(cacheKey);
    if (cachedPoster) {
      // Make a copy, because a fabric object should only be added to one canvas
      return copyFabricImage(cachedPoster);
    }
  }
  const rawPosterImage = await getRawPosterImage(iconFileKey);
  rawPosterImage.set({ _teleo_cached: true });
  rawPosterImageCache.set(cacheKey, rawPosterImage);
  return rawPosterImage;
};

const getPosterImage = async (
  iconFileKey: string | undefined,
  posterWidth: number,
  posterHeight: number
) => {
  const posterImage = await getRawPosterImageCached(iconFileKey);
  if (!posterImage) {
    throw new Error("No poster image");
  }

  const imgWidth = posterImage.width;
  const imgHeight = posterImage.height;
  if (!imgHeight || !imgWidth) {
    throw new Error("Poster image has no width or height");
  }
  const fitX = posterWidth / imgWidth;
  const fitY = posterHeight / imgHeight;
  const constrainedByWidth = fitX < fitY;
  const scale = constrainedByWidth ? fitX : fitY;
  const scaledImageHeight = imgHeight * scale;
  const scaledImageWidth = imgWidth * scale;
  // Center the poster
  const top = (posterHeight - scaledImageHeight) / 2;
  const left = (posterWidth - scaledImageWidth) / 2;
  posterImage.set({
    top,
    left,
    scaleX: scale,
    scaleY: scale,
    srcFromAttribute: true,
    backgroundColor: "#D8D5D6",
  });

  return {
    posterImage,
    imgWidth,
    imgHeight,
    scaledImageWidth,
    scaledImageHeight,
    top,
    left,
  };
};

const getMaskImage = async (
  flipOutline: boolean,
  scaledImageWidth: number,
  scaledImageHeight: number,
  top: number,
  left: number
) => {
  const maskOImg = await loadImage(posterMaskImage);
  const maskWidth = maskOImg.width;
  const maskHeight = maskOImg.height;
  if (!maskWidth || !maskHeight) {
    throw new Error("Poster image mask has no width or height");
  }
  const maskScaleX =
    (flipOutline ? scaledImageHeight : scaledImageWidth) / maskWidth;
  const maskScaleY =
    (flipOutline ? scaledImageWidth : scaledImageHeight) / maskHeight;
  const maskLeft = left + (flipOutline ? scaledImageWidth : 0);
  maskOImg.set({
    top,
    left: maskLeft,
    scaleX: maskScaleX,
    scaleY: maskScaleY,
    srcFromAttribute: true,
    angle: flipOutline ? 90 : 0,
    globalCompositeOperation: "destination-in", // use the mask as a mask
  });
  return {
    maskImage: maskOImg,
    maskLeft,
    maskScaleX,
    maskScaleY,
  };
};

const getOutlineImage = async (
  top: number,
  maskLeft: number,
  maskScaleX: number,
  maskScaleY: number,
  flipOutline: boolean
) => {
  const outlineOImg = await loadImage(posterOutlineImage);

  // Note the outline should have the same dimensions as the mask.
  outlineOImg.set({
    top,
    left: maskLeft,
    scaleX: maskScaleX,
    scaleY: maskScaleY,
    srcFromAttribute: true,
    angle: flipOutline ? 90 : 0,
  });

  return {
    outlineImage: outlineOImg,
  };
};

export const addPosterToRoom = async (
  backgroundImageSize: React.MutableRefObject<
    { width: number; height: number } | undefined
  >,
  canvasRef: React.MutableRefObject<fabricTypes.Canvas | undefined>,
  posterItem: RoomItemFragment,
  editItemIdRef: React.MutableRefObject<string | undefined>,
  newlyAddedRoomitemId: string | undefined,
  canMove: boolean,
  setDoneLoading: () => void,
  setError: () => void,
  dispatch: Dispatch
) => {
  try {
    const tmpPosterCanvas = new fabric.StaticCanvas(null);

    const posterWidth = (backgroundImageSize.current?.width || 0) * 0.15;
    const posterHeight = (backgroundImageSize.current?.height || 0) * 0.23;
    tmpPosterCanvas.setDimensions({
      width: posterWidth,
      height: posterHeight,
    });

    const iconFileKey = posterItem.icon_file?.key;
    const {
      posterImage,
      imgWidth,
      imgHeight,
      scaledImageWidth,
      scaledImageHeight,
      top,
      left,
    } = await getPosterImage(iconFileKey, posterWidth, posterHeight);
    tmpPosterCanvas.add(posterImage);

    const flipOutline = imgWidth > imgHeight; // avoid squishing the outline more than necessary

    const { maskImage, maskLeft, maskScaleX, maskScaleY } = await getMaskImage(
      flipOutline,
      scaledImageWidth,
      scaledImageHeight,
      top,
      left
    );
    tmpPosterCanvas.add(maskImage);

    const { outlineImage } = await getOutlineImage(
      top,
      maskLeft,
      maskScaleX,
      maskScaleY,
      flipOutline
    );
    tmpPosterCanvas.add(outlineImage);

    // Opaque overlay
    const rectangle = new fabric.Rect({
      top,
      left,
      width: scaledImageWidth,
      height: scaledImageHeight,
      fill: "white",
      opacity: 0.56,
      globalCompositeOperation: "source-atop",
    });
    tmpPosterCanvas.add(rectangle);

    tmpPosterCanvas.renderAll();

    const posterImg = new Image();
    posterImg.onload = () => {
      try {
        const posterOImg = new fabric.Image(posterImg);
        updateItemFields(
          posterOImg,
          posterItem,
          1,
          backgroundImageSize,
          editItemIdRef,
          newlyAddedRoomitemId,
          canMove,
          dispatch
        );
        if (canvasRef.current) {
          canvasRef.current.add(posterOImg);
          canvasRef.current.renderAll();
        }
        // Clear canvas contents when done to work around Safari canvas caching issues
        clearFabricCanvas(tmpPosterCanvas);
        setDoneLoading();
      } catch (e) {
        logUnexpectedError(e);
        setError();
        setDoneLoading();
      }
    };
    posterImg.src = tmpPosterCanvas.toDataURL();
  } catch (err) {
    logUnexpectedError(err);
    setDoneLoading();
    setError();
  }
};

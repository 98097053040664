import { sendEventToPeers } from "utils/webrtcUtils";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentRoomId,
  selectProviderId,
  selectUserRole,
  UserRole,
} from "redux/userRedux";
import {
  setAudioProviderOnly,
  setClientCanControl,
  setIsMuted,
  setIsPeerMuted,
  setIsPeerVideoOff,
  setIsVideoOff,
  setPeerIdWithControl,
  setVideoConferencing,
} from "redux/settingsRedux";
import { useApolloClient } from "@apollo/client";
import { evictCurrentRoom } from "utils/dbUtils";
import { setIsFullScreenVideo } from "redux/spaceNavigationRedux";
import {
  getLocalPeerId,
  Peers,
} from "pages/Space/hooks/connection/usePeerWebRTCConnection";
import { useTrackEvent } from "utils/metricsUtils";

export const EVENT_TYPES = {
  CLIENT_CONTROL: "client-control",
  AUDIO_PROVIDER_ONLY: "audio-provider-only",
  VIDEO_CONFERENCING: "video-conferencing",
  ROOM_CHANGED: "room-changed",
  FULL_SCREEN_VIDEO_CONFERENCING: "full-screen-video-conferencing",
  PEER_MUTED: "peer-muted",
  PEER_VIDEO_OFF: "peer-video-off",
  PEER_CAN_CONTROL_CURSOR: "peer-can-control-cursor",
} as const;

export type RemoteSetters = {
  setRemoteClientControl: (clientCanControl: boolean) => void;
  setRemoteAudioProviderOnly: (audioProviderOnly: boolean) => void;
  setRemoteVideoConferencing: (videoConferencing: boolean) => void;
  sendRemoteRoomChanged: () => void;
  setRemoteFullScreenVideoConferencing: (isFullScreen: boolean) => void;
  setRemoteIsPeerMuted: (isPeerMuted: boolean, peerId?: string) => void;
  setRemoteIsPeerVideoOff: (isPeerVideoOff: boolean, peerId?: string) => void;
  setRemotePeerIdWithControl: (peerId: string) => void;
};

export const useRemoteSettings = (
  peersRef: React.MutableRefObject<Peers>,
  peers: Peers
) => {
  const dispatch = useDispatch();
  const userRole = useSelector(selectUserRole);
  const roomId = useSelector(selectCurrentRoomId);
  const providerId = useSelector(selectProviderId);
  const roomIdRef = useRef(roomId); // for callback
  const client = useApolloClient();
  const providerIdRef = useRef<string>(); // for callback
  const { trackEvent } = useTrackEvent();

  useEffect(() => {
    roomIdRef.current = roomId;
  }, [roomId]);

  useEffect(() => {
    if (providerId) {
      providerIdRef.current = providerId;
    }
  }, [providerId]);

  const onReceiveMessageCallback = (event: MessageEvent) => {
    const localPeerId = getLocalPeerId();
    const data = JSON.parse(event.data);
    const eventType =
      data.event as typeof EVENT_TYPES[keyof typeof EVENT_TYPES];

    // Events both the therapist and client can trigger
    if (eventType === EVENT_TYPES.FULL_SCREEN_VIDEO_CONFERENCING) {
      const isFullScreen = data.data.isFullScreen;
      if (isFullScreen) {
        trackEvent("Video chat - talk mode opened", {
          ["source"]: "peer event",
        });
      } else {
        trackEvent("Video chat - talk mode closed", {
          ["source"]: "peer event",
        });
      }
      dispatch(setIsFullScreenVideo(isFullScreen));
    } else if (eventType === EVENT_TYPES.PEER_MUTED) {
      const isMuted = data.data.isPeerMuted;
      const targetId = data.data.targetPeerId;
      if (targetId === localPeerId) {
        dispatch(setIsMuted(isMuted));
      } else {
        dispatch(setIsPeerMuted({ peerId: targetId, isMuted }));
      }
    } else if (eventType === EVENT_TYPES.PEER_VIDEO_OFF) {
      const isVideoOff = data.data.isPeerVideoOff;
      const targetId = data.data.targetPeerId;
      if (targetId === localPeerId) {
        dispatch(setIsVideoOff(isVideoOff));
      } else {
        dispatch(setIsPeerVideoOff({ peerId: targetId, isVideoOff }));
      }
    } else if (eventType === EVENT_TYPES.PEER_CAN_CONTROL_CURSOR) {
      const targetId = data.data.targetPeerId;
      dispatch(setPeerIdWithControl(targetId));
    }

    if (userRole !== UserRole.CLIENT) {
      return;
    }

    // Events only the therapist can trigger
    if (eventType === EVENT_TYPES.CLIENT_CONTROL) {
      const clientCanControl = data.data.clientCanControl;
      dispatch(setClientCanControl(clientCanControl));
    } else if (eventType === EVENT_TYPES.AUDIO_PROVIDER_ONLY) {
      const audioProviderOnly = data.data.audioProviderOnly;
      dispatch(setAudioProviderOnly(audioProviderOnly));
    } else if (eventType === EVENT_TYPES.VIDEO_CONFERENCING) {
      const videoConferencing = data.data.videoConferencing;
      dispatch(setVideoConferencing(videoConferencing));
    } else if (eventType === EVENT_TYPES.ROOM_CHANGED) {
      if (providerIdRef.current) {
        evictCurrentRoom(providerIdRef.current)(client.cache);
      }
    }
  };

  useEffect(() => {
    // keep this array in scope to remove event listeners properly when unmounting
    const peersDataChannels = Object.values(peers).map(
      (peerObject) => peerObject.dataChannel
    );
    for (const peerDataChannel of peersDataChannels) {
      peerDataChannel?.addEventListener("message", onReceiveMessageCallback);
    }

    return () => {
      for (const peerDataChannel of peersDataChannels) {
        peerDataChannel?.removeEventListener(
          "message",
          onReceiveMessageCallback
        );
      }
    };
  }, [peers]);

  const setRemoteClientControl = (clientCanControl: boolean) => {
    sendEventToPeers(peersRef.current, EVENT_TYPES.CLIENT_CONTROL, {
      clientCanControl,
    });
  };

  const setRemoteAudioProviderOnly = (audioProviderOnly: boolean) => {
    sendEventToPeers(peersRef.current, EVENT_TYPES.AUDIO_PROVIDER_ONLY, {
      audioProviderOnly,
    });
  };

  const setRemoteVideoConferencing = (videoConferencing: boolean) => {
    sendEventToPeers(peersRef.current, EVENT_TYPES.VIDEO_CONFERENCING, {
      videoConferencing,
    });
  };

  const sendRemoteRoomChanged = () => {
    sendEventToPeers(peersRef.current, EVENT_TYPES.ROOM_CHANGED, {});
  };

  const setRemoteFullScreenVideoConferencing = (isFullScreen: boolean) => {
    sendEventToPeers(
      peersRef.current,
      EVENT_TYPES.FULL_SCREEN_VIDEO_CONFERENCING,
      { isFullScreen }
    );
  };

  const setRemoteIsPeerMuted = (isPeerMuted: boolean, peerId?: string) => {
    sendEventToPeers(peersRef.current, EVENT_TYPES.PEER_MUTED, {
      isPeerMuted,
      targetPeerId: peerId ?? getLocalPeerId(),
    });
  };

  const setRemoteIsPeerVideoOff = (isPeerVideoOff: boolean, peerId: string) => {
    sendEventToPeers(peers, EVENT_TYPES.PEER_VIDEO_OFF, {
      isPeerVideoOff,
      targetPeerId: peerId ?? getLocalPeerId(),
    });
  };

  const setRemotePeerIdWithControl = (peerId: string) => {
    sendEventToPeers(peers, EVENT_TYPES.PEER_CAN_CONTROL_CURSOR, {
      targetPeerId: peerId,
    });
  };

  return {
    setRemoteClientControl,
    setRemoteAudioProviderOnly,
    setRemoteVideoConferencing,
    sendRemoteRoomChanged,
    setRemoteFullScreenVideoConferencing,
    setRemoteIsPeerMuted,
    setRemoteIsPeerVideoOff,
    setRemotePeerIdWithControl,
  } as RemoteSetters;
};

import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { useSelector } from "react-redux";

import styles from "./DisabledOverlay.module.css";
import { selectIsProviderEditing } from "redux/editRoomNavigationRedux";

type DisabledOverlayProps = {
  message: string;
};

const DisabledOverlay = ({ message }: DisabledOverlayProps) => {
  const isProviderEditing = useSelector(selectIsProviderEditing);
  const [hasClicked, setHasClicked] = useState(false);

  useEffect(() => {
    if (!isProviderEditing) {
      setHasClicked(false);
    }
  }, [isProviderEditing]);

  return (
    <div
      className={clsx(styles.clickBlocker, {
        [styles.clickBlockerHidden]: !isProviderEditing,
      })}
      onClick={() => setHasClicked(true)}
    >
      <div
        className={clsx(styles.overlay, {
          [styles.overlayHidden]: !hasClicked,
        })}
      >
        <div className={styles.message}>{message}</div>
      </div>
    </div>
  );
};

export default DisabledOverlay;

import { useRef } from "react";
import { useTrackEvent } from "utils/metricsUtils";

const TITLE_FLASHING_INTERVAL = 1500;

/**
 * Custom hook that flashes the document title with a given message when the document is not in focus.
 * The title stops flashing when the document is focused.
 *
 * @param titleMessage - The message to flash in the document title.
 * @returns A function that starts the title flashing effect.
 *
 * @example
 * const flashTitle = useFlashTitle("New Notification!");
 * return <button onClick={() => flashTitle()} >Click me!</button>;
 */
export const useFlashTitle = (
  titleMessage: string,
  analyticsEventName: string
) => {
  const { trackEvent } = useTrackEvent();
  const originalTitleRef = useRef<string>(document.title);
  const intervalIdRef = useRef<NodeJS.Timer | undefined>(undefined);

  return () => {
    if (intervalIdRef.current) {
      return;
    }
    if (!document.hasFocus()) {
      trackEvent(`${analyticsEventName} - Title flash started`);

      document.title = titleMessage;
      intervalIdRef.current = setInterval(() => {
        if (document.hasFocus()) {
          trackEvent(`${analyticsEventName} - Title flash ended`);
          clearInterval(intervalIdRef.current);
          intervalIdRef.current = undefined;
          document.title = originalTitleRef.current;
          return;
        }

        document.title =
          document.title === originalTitleRef.current
            ? titleMessage
            : originalTitleRef.current;
      }, TITLE_FLASHING_INTERVAL);
    }
  };
};

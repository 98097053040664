import React from "react";

import { ReactComponent as HelpIcon } from "assets/icons/help_outline.svg";

import styles from "./HelpIndicator.module.css";
import Tooltip from "components/Tooltip/Tooltip";

type HelpIndicatorProps = { text: string };

const HelpIndicator = ({ text }: HelpIndicatorProps) => {
  return (
    <Tooltip text={text} hoverDelay={0}>
      <HelpIcon className={styles.icon} />
    </Tooltip>
  );
};

export default HelpIndicator;
